import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiInstance from "../config/api/axios";
import * as partyService from '../services/partyService';
import { IPartyData } from "../constants/partyTypes";
import { calcLength } from "framer-motion";


export type IUPartyState = {
    user: {
        dataParty: any[];
        isLoading: boolean;
        error: null | string;
    }
}

type partyState = {
    dataParty: any[];
    isLoading: boolean;
    error: null | string;
}

const initialState: partyState = {
    dataParty: [],
    isLoading: false,
    error: null,
}


const partySlice = createSlice({
    name: 'party',
    initialState,
    reducers: {
        statusLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        statusError(state, action) {
            state.error = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPartyListing.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(getPartyListing.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.dataParty = action.payload;
            })
            .addCase(getPartyListing.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(addParty.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addParty.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.dataParty.push(action.payload.party);
            })
            .addCase(addParty.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = action?.payload;
            })
    }
})

export default partySlice.reducer;
export const {statusLoading, statusError } = partySlice.actions

export const getPartyListing = createAsyncThunk("getPartyListing", async (data: any, thunkAPI): Promise<any> => {
    try {
        const response = await apiInstance.get(`/api/user/get-parties/${data}`)
        return response?.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
})

export const addParty = createAsyncThunk("addParty", async (data: any, thunkAPI): Promise<any> => {
    try {
        const response = await apiInstance.post(`/api/user/create-party`, data);
        return response?.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
})

//MIDDLE WARE
export const updateParty = (partyId: string, partyData: IPartyData | undefined) =>
    async (dispatch: any) => {
        try {
            const response = await partyService.updateParty(partyId, partyData);
            dispatch(getPartyListing(localStorage.getItem("companyId")))
            dispatch(statusLoading(false));
            dispatch(statusError(null));
        } catch (error) {
            dispatch(statusError(error))
            console.error("Error Updating Party:", error);
        }
    }
