import React from 'react';
import { Navigate, Outlet } from 'react-router-dom'

const AdminPublicRoutes = (props: any) => {
    let isSuperAdmin = localStorage?.getItem('isSuperAdmin');
    if(isSuperAdmin === '0'){
        return(<Navigate to="/login"/>)
    }
    let auth = localStorage?.getItem('token') ?  {'isLoggedIn': true} : {'isLoggedIn': false}
    return (auth.isLoggedIn ) ? <Navigate to="/admin/dashboard" /> : <Outlet />
}

export default AdminPublicRoutes;