import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Text,
  Table,
  Thead,
  Tr,
  Th,
  Flex,
  Image,
  Spacer,
  InputGroup,
  Input,
  Container,
  TableContainer,
  HStack,
} from "@chakra-ui/react";
// @ts-ignore
import imageUrl from '../logo.png'
import { IDeviceData } from "../constants/deviceTypes";
import { ICustomerData } from "../constants/customerTypes";
import { IUserData } from "../constants/userTypes";
import { Button } from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";
import Logout from '../components/Logout'
type Props = {
  newData: IUserData | ICustomerData | IDeviceData;
  children: React.ReactNode;
  title: string;
  subtitleCustomer: string;
  subtitleDevice: string;
  subtitleUser: string;
  pageType: string | undefined;
  headers: string[];
  companyId?: string;
  handleSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDrawerAdd?: any;
  dataCustomer?: any;
}

const CustomTable: React.FC<Props> = ({
  children,
  title,
  subtitleCustomer,
  subtitleDevice,
  subtitleUser,
  pageType,
  handleDrawerAdd,
  headers,
  companyId,
  handleSearch,
  dataCustomer,
}) => {
  const navigate = useNavigate()
  const [customerNameState, setCustomerNameState] = useState<any>([])

  useEffect(() => {
    const companyName = dataCustomer?.filter((data: any) => {
      return data._id === companyId
    })
    setCustomerNameState(companyName)
  }, [dataCustomer])

  return (
    <>
      <Container
        height={"20"}
        bgColor={"#fff"}
        maxW={'container.xxl'}
      >
        <Flex
          flexDirection={["column", "row"]}
          alignItems={"center"}
          mt={"10px"}
        >
          <Flex
            flexDirection={"column"}
            alignItems={"center"}
          >
            <HStack >
              <Image src={imageUrl} alt="Image Description" boxSize="50px" />
              <Text fontSize="3xl" fontWeight="bold">
                {title}
              </Text>
            </HStack>
          </Flex>

          <Spacer />

          <Flex
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Text mr={"10px"} fontSize="1xl" fontWeight="bold" >
              {localStorage.getItem("adminUserName")}
            </Text>
          </Flex>

          <Flex
            flexDirection={"column"}
            alignItems={"center"}
          >
            <HStack>
              <InputGroup justifyContent="flex-end">
                <Input
                  size={"sm"}
                  type="text"
                  placeholder="Type to search"
                  onChange={handleSearch}
                  width="200px"
                />
              </InputGroup>
              <Box pt={"11px"}>
                <Logout />
              </Box>
            </HStack>
          </Flex>
        </Flex>

        {/* ANOTHER ROW */}
        <Flex
          flexDirection={"row"}
          textAlign={"center"}
          mt={["2px", "20px"]}
          mb={["0px", "10px"]}
        >
          <Text ml={"10px"} fontSize="lg" fontWeight="bold" color={"#66B2B2"}>
            {companyId && customerNameState && customerNameState[0]?.customerName}
          </Text>
        </Flex>


        {/* ANOTHER ROW */}
        <Flex
          flexDirection={["column", "row"]}
          textAlign={"center"}
        >
          <Flex
            flexDirection={"column"}
            textAlign={"center"}
          >
            <Button
              size={"sm"}
              onClick={() => navigate("/admin/dashboard")}
              colorScheme={pageType === 'customer' ? 'teal' : 'gray'}
            >
              {subtitleCustomer}
            </Button>
          </Flex>

          <Flex
            flexDirection={"column"}
            textAlign={"center"}
            mt={["8px", "0px"]}
          >
            {
              companyId ?
                <Button
                  size={"sm"}
                  ml={["0px", "10px"]}
                  onClick={pageType === 'devices' ? () => navigate(`/admin/devices/${companyId}`) : () => navigate(`/admin/devices`)}
                  colorScheme={pageType === 'devices' ? 'teal' : 'gray'}
                >
                  {subtitleDevice}
                </Button> :
                <Button
                  size={"sm"}
                  ml={["0px", "10px"]}
                  onClick={pageType !== 'devices' ? () => navigate(`/admin/devices`) : undefined}
                  colorScheme={pageType === 'devices' ? 'teal' : 'gray'}
                >
                  {subtitleDevice}
                </Button>
            }
          </Flex>



          <Flex
            flexDirection={"column"}
            textAlign={"center"}
            mt={["8px", "0px"]}
          >
            <Button
              size={"sm"}
              ml={["0px", "10px"]}
              _hover={{
                cursor: "default"
              }}
              _active={{}}
              bgColor={"#fff"}
              variant={"outline"}
              onClick={pageType === 'users' ? () => navigate(`/admin/users/${companyId}`) : undefined}
              colorScheme={pageType === 'users' ? 'teal' : 'gray'}
            >
              {subtitleUser}
            </Button>
          </Flex>

            <Spacer/>
          <Flex
            flexDirection={"column"}
            textAlign={"center"}
            mt={["8px", "0px"]}
          >
            <Button
              size={"sm"}
              mr={["0px", "10px"]}
              colorScheme={'teal'}
              // bgColor={"#fff"}
              // variant={"outline"}
              onClick={() => handleDrawerAdd()}
            >
              {pageType === "devices" && "Add device"}
              {pageType === "customer" && "Add customer"}
              {pageType === "users" && "Add users"}
            </Button>
          </Flex>
        </Flex>



        {/* ANOTHER ROW */}
        <Flex
          flexDirection={"row"}
          alignItems={"center"}
          mt={"30px"}
        >
          <TableContainer width={"100%"}>
            <Table size='sm'>
              <Thead>
                <Tr >
                  {headers.map((header, index) => (
                    index === 0 ? (
                      <Th
                        fontSize={"sm"}
                        // width="10px"
                        key={index}
                      // margin="0.2rem"
                      >{header}</Th>
                    ) : (
                      <Th
                        // width="1%"
                        key={index}
                      // margin="0.2rem"

                      >{header}</Th>
                    )
                  ))}
                </Tr>
              </Thead>
              {children}
            </Table>
          </TableContainer>
        </Flex>

      </Container>
    </>


  );
};

export default CustomTable;
