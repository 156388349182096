import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as userServices from '../services/userServices';
import apiInstance from "../config/api/axios";

export type IURootState = {
    user: {
        dataUser: any[];
        isLoading: boolean;
        error: null | string;
    }
}

type UserState = {
    dataUser: any[];
    isLoading: boolean;
    error: null | string;
}

const initialState: UserState = {
    dataUser: [],
    isLoading: true,
    error: null,
}


const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        getAllUser(state, action) {
            state.dataUser = action.payload;
        },
        resetDeviceById(state) {
            state.dataUser = [];
            state.isLoading = false;
            state.error = null;
          },
        onChangeUser(state, action) {
            const { index, fieldName, value } = action.payload;
            state.dataUser = state.dataUser.map((data, i) => {
                if (index === i) {
                    if (fieldName === "isSupervisor" && value === "1") {
                        return {
                            ...data,
                            rights: [],
                            [fieldName]: value === "1" ? true : false,
                        };

                    } else if (fieldName === "isSupervisor" && value !== "1") {
                        return {
                            ...data,
                            [fieldName]: value === "1" ? true : false,
                        };
                    } else {
                        return {
                            ...data,
                            [fieldName]: value,
                        };
                    }
                }
                return data;
            });
        },
        statusLoading(state, action) {
            state.isLoading = action.payload
        },
        statusError(state, action) {
            state.error = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserInfo.pending, (state) => {
            state.isLoading = true
        })
            .addCase(getUserInfo.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.dataUser = action.payload;
            })
            .addCase(getUserInfo.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = action.payload
            })
            .addCase(getMyCompanyUsers.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getMyCompanyUsers.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.dataUser = action.payload;
            })
            .addCase(getMyCompanyUsers.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = action?.payload;
            })
            .addCase(updateUserdeviceAccess.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateUserdeviceAccess.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = null;
                state.dataUser = action.payload;
            })
            .addCase(updateUserdeviceAccess.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = action?.payload;
            })
    }
})

export default userSlice.reducer;
export const { getAllUser, resetDeviceById, onChangeUser, statusLoading, statusError } = userSlice.actions;


// Middleware 
export const fetchUser = (comapanyId: string | undefined) =>
    async (dispatch: any) => {
        try {
            const response = await userServices.userList(comapanyId)
            dispatch(getAllUser(response.data.payload));
            dispatch(statusLoading(false));
            dispatch(statusError(null));
        } catch (error) {
            dispatch(statusError(error))
            console.error("Error fetching customer data:", error);
        }
    }

// API FOR GET ALL USERS INFO
export const getUserInfo = createAsyncThunk("getUserInfo", async (_, thunkAPI): Promise<any> => {
    try {
        const response = await apiInstance.get(`/api/user/get-user-info`)
        return response?.data?.payload
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
})

// API FOR GET UER BY COMPANYID:
export const getMyCompanyUsers = createAsyncThunk("getMyCompanyUsers", async (_, thunkAPI): Promise<any> => {
    try {
        const response = await apiInstance.get(`/api/user/get-my-company-users`);
        return response?.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
})

// API FOR GET UER BY COMPANYID:
export const updateUserdeviceAccess = createAsyncThunk("updateUserdeviceAccess", async (dataPayload:any, thunkAPI): Promise<any> => {
    try {
        const response = await apiInstance.patch(`/api/user/update-user-device-access`, dataPayload);
        return response?.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
})
