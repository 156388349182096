import axios from 'axios'
// import { URL } from '../../constants/utils';

const apiInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL, // Set the base URL for all requests
});

apiInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')
    config.headers.Authorization = token && token !== 'undefined' ? `Bearer ${token}` : ''
    return config
})

export default apiInstance