import apiInstance from "../config/api/axios"
import { ICustomerData } from "../constants/customerTypes"

//SHOWING CUSTOMER LIST
export const customerList = async () => {
    const response = await apiInstance.get(`/api/admin/companies`);
    return response
}

// ADDING NEW CUSTOMER
export const addCustomer = async (data: ICustomerData) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
        const response = await apiInstance.post(`/api/admin/company`, data, config);
        return response;
    } catch (error) {
        console.error('Error in addCustomer:', error);
        throw error
    }
    // export const addCustomer = async (data: FormData) => {
    // const config = {
    //     headers: {
    //         'Content-Type': 'multipart/form-data'
    //     }
    // };
    // const response = await apiInstance.post(`/api/admin/company`, data, config);

}

// UPDATE CUSTOMER
export const updateCustomer = async (editCustomerData: ICustomerData | undefined, custEditId: number) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
        const response = await apiInstance.patch(`/api/admin/company/${custEditId}`, editCustomerData, config);
        return response
    } catch (error) {
        console.error('Error in addCustomer:', error);
        throw error
    }

}
