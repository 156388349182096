import apiInstance from "../config/api/axios";
import { IUserData, IEditUserData } from "../constants/userTypes";

// ADDING NEW USER:
export const addUser = async (data: IUserData) => {
    const response = await apiInstance.post(`/api/admin/create-user`, data);
    return response;
}

//SHOW USER LIST
export const userList = async (companytId: string | undefined): Promise<any> => {
    const response = await apiInstance.get(`/api/admin/get-users/${companytId}`);
    return response
}

// UPDATE USER
export const updateUser = async (editUserData: IUserData, userEditId: number) => {    
    const editData: IEditUserData = {
        username: editUserData?.username,
        designation: editUserData?.designation,
        isSupervisor: editUserData?.isSupervisor ? "1" : "0",
        userDevices: editUserData?.userDevices,
        isActive: editUserData?.isActive,
    }
    const response = await apiInstance.patch(`/api/admin/update-user/${userEditId}`, editData);
    return response
}


export const userSubscription = async (tokenID: string | null) => {
    try {
        const response = await apiInstance.patch(`/api/user/subscribe`,{subscriptionDeviceId: tokenID});
        return response
    } catch (error) {
        throw(error)
    }
}


export const requestNotification = async () => {
    try {
        const response = await apiInstance.post(`/api/user/request-test-notification`)
        return response
    } catch (error: any) {
        throw(error)
    }
}
