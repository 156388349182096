import apiInstance from "../config/api/axios"
import { IDeviceData } from "../constants/deviceTypes";


// UPDATE DEVICE
export const updateDevice = async (editDeviceData:IDeviceData | undefined, editId: string) => {
    const response = await apiInstance.patch(`/api/admin/device/${editId}`, editDeviceData);
    return response
}



// ADDING NEW USER:
export const addDevice = async (data: IDeviceData) => {
    const response = await apiInstance.post(`/api/admin/device`, data);
    return response;
}

