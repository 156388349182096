import React, { useEffect } from "react";
import { useToast } from "@chakra-ui/react";

type ShowToastProps = {
    message: string | undefined;
    resStatus?: 'info' | 'warning' | 'success' | 'error' | 'loading';
    setFunction?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ShowToast: React.FC<ShowToastProps> = ({ message, resStatus, setFunction }) => {
    const toast = useToast();

    useEffect(() => {
        if (setFunction) {
            setFunction(false); // Update setFunction when component mounts or updates
        }
    }, [setFunction]); // Ensure setFunction is only updated when it changes

    useEffect(() => {
        if (message && resStatus) {
            toast({
                title: message,
                status: resStatus,
                duration: 2000, // 2 seconds
                isClosable: true,
                position: 'top',
                containerStyle: {
                    width: '500px',
                    maxWidth: '100%',
                },
            });
        }
    }, [message, resStatus, toast]);

    return null; // Toasts are shown via side effect, no need for render content
};

export default ShowToast;
