import React from 'react';
import { Spinner } from '@chakra-ui/react';

const Loader = () => {
  return (
    <div
      style={{
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        zIndex: 9999,
      }}
    >
      <Spinner size="xl" color="teal.500" thickness="4px" />
    </div>
  )
};

export default Loader;
