import { configureStore } from "@reduxjs/toolkit";
import customerReducer from "./customerSlice";
import userReducer from "./userSlice";
import deviceReducer from "./deviceSlice";
import jobReducer from "./jobSlice";
import partyReducer from "./partySlice";

const store = configureStore({
    reducer:{
        customer: customerReducer,
        user: userReducer,
        device: deviceReducer,
        job: jobReducer,
        party: partyReducer,
    }
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;