import React, { useEffect, useState } from "react";
import {
  Input,
  Tbody,
  Tr,
  Td,
  Icon,
  Tooltip,
  useDisclosure,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  Select,
  FormLabel
} from "@chakra-ui/react";
import { EditIcon, AddIcon } from "@chakra-ui/icons";
import CustomTable from "../components/customTable";
import Loader from "../components/loader";
import { IDeviceData } from "../constants/deviceTypes";
import { useParams } from "react-router-dom";
import * as deviceServices from '../services/deviceServices';
import { IDRootState, getDevicesById, getAllDevices, onChangeDevice } from "../Redux/deviceSlice";
import { useAppDispatch, useAppSelector } from '../Redux/hooks';
import { ICRootState, getCustomerById, resetCustomerById } from "../Redux/customerSlice";
import ShowToast from "../components/ShowToast";
const initialDevice: IDeviceData = {
  _id: "",
  deviceId: "",
  deviceName: "",
  deviceLocation: "",
  companyId: "",
  isActive: true,
};




const DeviceList: React.FC = () => {
  const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure()
  const { isOpen: isOpenAdd, onOpen: onOpenAdd, onClose: onCloseAdd } = useDisclosure()
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const companyId: string | undefined = id;
  const [newData, setNewData] = useState<IDeviceData>(initialDevice);
  const { dataDevice, isLoading, error } = useAppSelector((state: IDRootState) => state.device)
  const { dataCustomer } = useAppSelector((state: ICRootState) => state.customer)
  const [deviceListing, setDeviceListing] = useState<any[]>([]);
  const [editDeviceIndex, setEditDeviceIndex] = useState<number | undefined>()
  const [deviceDataToEdit, setDeviceDataToEdit] = useState<IDeviceData | undefined>(undefined);


  const [deviceResponseSuccess, setDeviceResponseSuccess] = useState<boolean>(false)
  const [deviceResponseError, setDeviceResponseError] = useState<boolean>(false)
  const [deviceResponseMessage, setDeviceResponseMessage] = useState<string | undefined>()










  // GET ALL DEVICES RELATED TO COMAPNY:
  useEffect(() => {
    if (companyId) {
      dispatch(getDevicesById(companyId))
      dispatch(getCustomerById(companyId))
    } else {
      dispatch(getAllDevices())
    }

    return () => {
      // Dispatch an action to reset or clear the state as needed
      dispatch(resetCustomerById());
    };
  }, [dispatch]);


  // SETTING THE DATADEVICE TO DEVICE LISTING
  useEffect(() => {
    setDeviceListing(dataDevice)
  }, [dataDevice])


  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimeout(() => {
      const searchTerm = e.target.value.toLowerCase();
      const searchDeviceRes = dataDevice.filter((item) => {
        const name = item.deviceName.toLowerCase(); // Assuming 'name' is the property name containing the customer's name
        return name.includes(searchTerm);
      });

      if (searchDeviceRes.length > 0) {
        setDeviceListing(searchDeviceRes);
      } else {
        setDeviceListing([]);
      }
    }, 2000);
  }

  // MANGE DRAWER FOR EDIT THE PARTCULAR DATA OPEN , INITIALIZE, SET INDEX OF DATA AND FIND THE DATA TO SHOW FURTHUR FOR EDITING
  const handleDrawerEdit = (index: any) => {
    onOpenEdit()
    setEditDeviceIndex(index)
    const editDeviceData: IDeviceData | undefined = dataDevice.find((value: any, deviceIndex: number) => {
      return deviceIndex === index
    })
    editDeviceData && setDeviceDataToEdit(editDeviceData);
  }


  // MANAGE THE ON CHANGE STATE FOR DEVICE EDIT SECTION
  const handleEditDeviceChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index?: number
  ) => {
    const { name, value } = e.target;
    // PARSE THE STATUS AS A BOOLEAN
    const parsedValue = name === "isActive" ? (value === "1" ? true : false) : value;
    if (deviceDataToEdit) {
      setDeviceDataToEdit({
        ...deviceDataToEdit,
        [name]: parsedValue
      });
    } else {
      console.error('Device data for edit is undefined.');
    }
  }


  // HANDLE THE SUBMIT FOR THE EDIT DEVICE DATA:
  const handleEditDevice = async (index?: number) => {

    if (deviceDataToEdit?.deviceName && deviceDataToEdit?.deviceLocation) {
      const deviceEditId = deviceDataToEdit?._id;
      try {
        // API HIT FOR UPDATE THE DEVICE DATA:
        const response = await deviceServices.updateDevice(deviceDataToEdit as IDeviceData, deviceEditId as any)
        setDeviceResponseSuccess(true)
        setDeviceResponseMessage(response?.data?.message)
        setDeviceDataToEdit(initialDevice)
        onCloseEdit()
        if (companyId) {
          dispatch(getDevicesById(companyId))
        } else {
          dispatch(getAllDevices())
        }
      } catch (error: any) {
        setDeviceResponseError(true)
        setDeviceResponseMessage(`Error Updating Data: ${error}`)
      }
    } else {
      setDeviceResponseError(true)
      setDeviceResponseMessage("All feilds are required.")
    }
  };



  // HANDLE SUBMIT OF NEW DATA FOR DEVICE AND CALL API TO INSERT DATA
  const handleAddDevice = async () => {
    if (newData.deviceName && newData.deviceLocation) {

      //HANDLE DEVICE DATA:
      const deviceData: IDeviceData = {
        _id: "",
        deviceId: newData.deviceId,
        deviceName: newData.deviceName,
        deviceLocation: newData.deviceLocation,
        isActive: newData.isActive,
        companyId: companyId,
      }


      //HITTING THE API HERE FOR ADD NEW DEVICE:
      try {
        const response = await deviceServices.addDevice(deviceData);
        setNewData(initialDevice);
        setDeviceResponseSuccess(true)
        setDeviceResponseMessage(response?.data?.message)
        dispatch(getDevicesById(companyId))
        onCloseAdd()
      } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'An error occurred';
        setDeviceResponseError(true)
        setDeviceResponseMessage(errorMessage)
      }
    } else {
      setDeviceResponseError(true)
      setDeviceResponseMessage("All feilds are required.")
    }
  };


  // MANAGING THE ONCHANGE STATE FOR EACH FIELD FOR NEW DATA FOR DEVIVE
  const handleNewData = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setNewData({
      ...newData,
      [e.target.name]: e.target.value
    })
  }


  // OPENING THE DRAWER AND MAKE INITIAL STATE TO EMPTY FOR ADDING DEVICE
  const handleDrawerAdd = () => {
    onOpenAdd()
    setNewData(initialDevice)
  }


  // ONCLICK EVENT FOR OPENING A DRAWER ON CLICKING ADD DEVICE
  const handleAddRow = () => {
    return (
      <Tr>
        <Td
          colSpan={5}
          pt={"20px"}
          textAlign={"center"}
          fontSize={"15px"}
          color={'teal'}
          fontWeight={"bold"}
          opacity={"0.7"}
        >
          No device found add device
        </Td>
        {/* <Td>
          <Tooltip label="Add" hasArrow aria-label='A tooltip' bg='teal.500' color='white'>
            <Icon as={AddIcon} mx={3} _hover={{ cursor: "pointer" }} boxSize={4} onClick={handleDrawerAdd} />
          </Tooltip>
        </Td> */}
      </Tr>
    );
  };

  return (
    <>
      {isLoading ? <Loader /> : <CustomTable
        newData={newData}
        title="IOT Dashboard"
        subtitleCustomer="Customer Management"
        subtitleDevice="Device Management"
        subtitleUser="User Management"
        pageType="devices"
        handleDrawerAdd={handleDrawerAdd}
        headers={[
          "ID",
          "Name",
          "Location ",
          "IsActive",
          "Action"
        ]}
        companyId={companyId}
        handleSearch={handleSearch}
        dataCustomer={dataCustomer}
      >

        {deviceResponseSuccess && <ShowToast message={deviceResponseMessage} resStatus="success" setFunction={setDeviceResponseSuccess} />}
        {deviceResponseError && <ShowToast message={deviceResponseMessage} resStatus="error" setFunction={setDeviceResponseError} />}

        <Tbody>
          {deviceListing && deviceListing.map((item, index) => {
            const backgroundColor = item?.isActive ? "#b3ffb3" : "#ffc2b3"; // Determine background color based
            return (
              <Tr key={index}>
                <Td>
                  {item?.deviceId}
                </Td>
                <Td>
                  {item?.deviceName}
                </Td>
                <Td>
                  {item?.deviceLocation}
                </Td>
                <Td bgColor={backgroundColor}>
                  {item.isActive ? "Active" : "InActive"}
                </Td>
                <Td>
                  <Tooltip label="Update" hasArrow aria-label='A tooltip' bg='teal.500' color='white'>
                    <EditIcon mx={2} _hover={{ cursor: "pointer" }} boxSize={4} onClick={() => handleDrawerEdit(index)} />
                  </Tooltip>
                </Td>
              </Tr>
            )
          })
          }
          {
            deviceListing && deviceListing?.length === 0 && handleAddRow()
          }
          {/* {handleAddRow()} */}
        </Tbody>
      </CustomTable>}

      <Drawer
        isOpen={isOpenAdd}
        placement='right'
        onClose={onCloseAdd}
        size={"lg"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottom={"1px solid #DADADA"} boxShadow={"3px 6px 8px 0px #DADADA"}>
            Add device
          </DrawerHeader>

          <DrawerBody>
            <FormControl isRequired mt={"20px"}>
              <FormLabel>Name</FormLabel>
              <Input
                size={"sm"}
                placeholder="Enter device name"
                name="deviceName"
                value={newData.deviceName}
                onChange={(e) => handleNewData(e)}
              />
            </FormControl>

            <FormControl isRequired mt={"20px"}>
              <FormLabel>Location</FormLabel>
              <Input
                size={"sm"}
                placeholder="Enter device location"
                name="deviceLocation"
                value={newData.deviceLocation}
                onChange={(e) => handleNewData(e)}
              />
            </FormControl>
            <Button mt={"20px"} onClick={() => handleAddDevice()} size={"sm"} fontSize={"12px"} colorScheme="teal"> Add </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* DRAWER FOR EDIT */}
      <Drawer
        isOpen={isOpenEdit}
        placement='right'
        onClose={onCloseEdit}
        size={"lg"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottom={"1px solid #DADADA"} boxShadow={"3px 6px 8px 0px #DADADA"}>
            Update device
          </DrawerHeader>
          <DrawerBody>
            <FormControl isRequired mt={"20px"}>
              <FormLabel>Name</FormLabel>
              <Input
                size={"sm"}
                placeholder="Enter device name"
                name="deviceName"
                value={deviceDataToEdit?.deviceName}
                onChange={(e) => handleEditDeviceChange(e, editDeviceIndex)}
              />
            </FormControl>

            <FormControl isRequired mt={"20px"}>
              <FormLabel>Location</FormLabel>
              <Input
                size={"sm"}
                placeholder="Enter device location"
                name="deviceLocation"
                value={deviceDataToEdit?.deviceLocation}
                onChange={(e) => handleEditDeviceChange(e, editDeviceIndex)}
              />
            </FormControl>

            <FormControl isRequired mt={"20px"}>
              <FormLabel>isActive</FormLabel>
              <Select
                fontSize={"sm"}
                // placeholder="Select isActive"
                size={"sm"}
                name="isActive"
                value={deviceDataToEdit?.isActive ? "1" : "0"}
                onChange={(e) => handleEditDeviceChange(e, editDeviceIndex)}
              >
                <option value={1}>Active</option>
                <option value={0}>Inactive</option>
              </Select>
            </FormControl>

            <Button mt={"20px"} onClick={() => handleEditDevice(editDeviceIndex)} size={"sm"} fontSize={"12px"} colorScheme="teal"> Update </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default DeviceList;
