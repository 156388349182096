import React, { useState, useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import CustomerList from './views/customerList';
import SignUp from './views/signup';
import UserList from './views/userList';
import DeviceList from './views/deviceList';
import UserDashboard from './views/userDashboard';
import Galvanization from './views/galvanization';
import AdminLogin from './views/adminLogin';
import UserLogin from './views/userLogin';
// import UserProfile from './views/UserProfile';
import UserPrivateRoutes from './authRoutes/userPrivateRoutes';
import UserPublicRoutes from './authRoutes/userPublicRoutes';
import AdminPrivateRoutes from './authRoutes/adminPrivateRoutes';
import AdminPublicRoutes from './authRoutes/adminPublicRoutes';
import Forging from './views/forging';
const showModule = localStorage.getItem('customerType')


const Router = () => {
  const [customerType, setCustomerType] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedCustomerType = localStorage.getItem('customerType');
    if (storedCustomerType) {
      setCustomerType(storedCustomerType);
      navigate('/dashboard');
    }
  }, [navigate]);

  return (
    <>
      <Routes>
        <Route path='/'>
          <Route path='/' element={<Navigate to="/login" />} />
          {/* <Route path='/' element={<UserLogin />} /> */}
          <Route element={<UserPrivateRoutes />}>
            {/* Conditionally render different dashboards based on customerType */}
            {customerType === 'forging' && (
              // <Route path='dashboard' element={<UserDashboard />} />
              <Route path='dashboard' element={<Forging />} />
            )}

{/*             
            {customerType === 'forging' && (
              <Route path='/profile' element={<UserProfile />} />
            )} */}


            {customerType === 'galvanization' && (
              <Route path='dashboard' element={<Galvanization />} />
            )}
            {/* { IF ABOVE TO ROUTES NOT MATCHED THEN REDIRECT IT TO LOGIN FOR CHECKING CONDITION IS LOGGED IN OR NOT} */}
            <Route path="dashboard" element={<UserLogin />} />

          </Route>

          <Route element={<UserPublicRoutes />}>
            <Route path="login" element={<UserLogin />} />
          </Route>
          <Route path="*" element={<Navigate to="dashboard" />} />
        </Route>

        <Route path="/admin">
          <Route path='/admin' element={<Navigate to="login" />} />
          <Route element={<AdminPrivateRoutes />}>
            <Route path="dashboard" element={<CustomerList />} />
            <Route path="users/:id" element={<UserList />} />
            <Route path="devices/:id?" element={<DeviceList />} />
          </Route>
          <Route element={<AdminPublicRoutes />}>
            <Route path="login" element={<AdminLogin />} />
          </Route >


          <Route path="signup" element={<SignUp />} />
          <Route path="*" element={<Navigate to="dashboard" />} />
        </Route>

        <Route path="*" element={<h1>Not found</h1>} />
      </Routes >
    </>

  );
}

export default Router;