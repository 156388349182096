import React, { useEffect } from "react";
import { useState } from "react";
import {
    Box,
    Button,
    HStack,
    Spacer,
    VStack,
    Text,
    Input,
    Divider,
} from '@chakra-ui/react';
// @ts-ignore
import styles from '../../src/assets/module/global.module.css';

import ShowToast from "./ShowToast";
import { useAppDispatch, useAppSelector } from '../Redux/hooks';
import { addParty, updateParty, getPartyListing } from "../Redux/partySlice";
import Loader from "./loader";

const ManagePartiesComponent: React.FC<{ buttonType: string; setButtonType: React.Dispatch<React.SetStateAction<string>> }> = ({ buttonType, setButtonType }) => {
    const [partyName, setPartyName] = useState('');
    const [isEditing, setIsEditing] = useState<number | null>(null);
    const [editedPartyName, setEditedPartyName] = useState('');
    const [partyAddedMessage, setPartyAddedMessage] = useState(false)
    const [errorPartyAddedMessage, setErrorPartyAddedMessage] = useState(false)
    const dispatch = useAppDispatch();
    const { dataParty, isLoading } = useAppSelector((state) => state.party)
    // console.log('data party---------------redux state', dataParty)
    const [partyList, setPartyList] = useState<any[]>([]);
    // GET ALL PARTY LIST API:
    useEffect(() => {
        dispatch(getPartyListing(localStorage.getItem("companyId")));
    }, [dispatch])

    useEffect(() => {
        setButtonType(buttonType)
        setPartyList(dataParty)
    }, [dataParty])

    const handleAddParty = () => {

        if (partyName.trim() !== '') {
            // DISPATCH THE ACTION FOR ADD PARTY
            const partyAddData = {
                "partyName": partyName,
                "companyId": localStorage.getItem("companyId")
            }
            dispatch(addParty(partyAddData))
            setPartyAddedMessage(true)
            setPartyName('');
        } else {
            setErrorPartyAddedMessage(true)
        }
    };


    const handleEditParty = (index: number) => {
        setIsEditing(index);
        setEditedPartyName(partyList[index].partyName);
    };

    const [partyUpdatedMessage, setPartyUpdatedMessage] = useState(false)

    const handleSaveEdit = (index: number) => {
        if (editedPartyName) {
            const updatedParties = [...partyList];
            updatedParties[index] = { ...updatedParties[index], partyName: editedPartyName };
            const partyId = updatedParties[index]._id;
            const companyId = localStorage.getItem("companyId");

            const data: {
                partyName: string;
                companyId: string;
            } = {
                partyName: editedPartyName,
                companyId: companyId || "",
            };

            dispatch(updateParty(partyId, data))


            setPartyList(updatedParties);
            setIsEditing(null);
            setEditedPartyName('');
            setPartyUpdatedMessage(true)
        } else {
            setErrorPartyAddedMessage(true)
        }
    };

    return (
        <Box>
            
            {partyAddedMessage && <ShowToast message="Party added successfully" resStatus="success" setFunction={setPartyAddedMessage} />}
            {errorPartyAddedMessage && <ShowToast message="Enter party name" resStatus="error" setFunction={setErrorPartyAddedMessage} />}
            {partyUpdatedMessage && <ShowToast message="Party updated successfully" resStatus="success" setFunction={setPartyUpdatedMessage} />}

            <Text fontSize="md" mb={2} mt={"30px"}>Manage Parties Content</Text>
            <VStack spacing={4} align="stretch" >
                <Input
                    fontSize={"sm"}
                    mt={"5px"}
                    placeholder="Enter party name"
                    value={partyName}
                    onChange={(e) => setPartyName(e.target.value)}
                />
                <Button className={styles.tableBtn} colorScheme="teal" onClick={handleAddParty}>Add Party</Button>
            </VStack>

            <VStack align="start" mt={4}>
                {isLoading ? <Loader /> :
                    <>
                        <Text fontSize="md">Party List :</Text>
                        
                        {partyList?.map((party: any, index) => (

                            <HStack key={index} width="100%">
                                {isEditing === index ? (
                                    <>
                                        <Input
                                            value={editedPartyName}
                                            onChange={(e) => setEditedPartyName(e.target.value)}
                                        />
                                        <Button size="sm" colorScheme="teal" onClick={() => handleSaveEdit(index)}>Save</Button>
                                    </>
                                ) : (
                                    <>
                                        <Text fontSize={"sm"}>{`${index + 1}: ${party.partyName}`}</Text>
                                        <Spacer />
                                        <Button size="sm" onClick={() => handleEditParty(index)}>Edit</Button>
                                    </>
                                )}
                            </HStack>
                        ))}
                    </>
                }


            </VStack>
        </Box>
    );
};

export default ManagePartiesComponent;