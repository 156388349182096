import React, { useEffect, useState } from "react";
import {
  Input,
  Tbody,
  Tr,
  Td,
  Select,
  Icon,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  MenuDivider,
  Button,
  useDisclosure,
  Drawer,
  DrawerBody,
  Text,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Stack,
  Checkbox,
  CheckboxGroup

} from "@chakra-ui/react";
import { EditIcon, AddIcon } from "@chakra-ui/icons";
import CustomTable from "../components/customTable";
import Loader from "../components/loader";
import { IUserData } from "../constants/userTypes";
import * as userServices from '../services/userServices';
import { useParams } from "react-router-dom";
import { fetchUser, onChangeUser, resetDeviceById } from "../Redux/userSlice";
import { IURootState } from "../Redux/userSlice";
import { useAppDispatch, useAppSelector } from '../Redux/hooks';
import { ICRootState, getCustomerById, resetCustomerById } from "../Redux/customerSlice";
import { getDevicesById } from "../Redux/deviceSlice";
import ShowToast from "../components/ShowToast";

const initialUser: IUserData = {
  _id: "",
  // userId: "",
  username: "",
  email: "",
  password: "",
  designation: "",
  isSupervisor: false,
  rights: "",
  userDevices: [],
  companyId: "",
  isActive: true,
};


const UserList: React.FC = () => {
  const { isOpen: isOpenAdd, onOpen: onOpenAdd, onClose: onCloseAdd } = useDisclosure()
  const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure()

  const { id } = useParams();
  const companyId: string | undefined = id;

  const dispatch = useAppDispatch();
  const [newData, setNewData] = useState<IUserData>(initialUser);
  const { dataUser, isLoading, error } = useAppSelector((state: IURootState) => state.user)
  const { dataCustomer } = useAppSelector((state: ICRootState) => state.customer)
  const { dataDevice } = useAppSelector((state) => state.device)
  const [showDevices, setShowDevices] = useState(false)
  const [userListing, setUserListing] = useState<any[]>([]);
  const [userDevice, setUserDevice] = useState<any[]>([]);
  // HANDLE ADD CHECKBOX MULTI SELECT HERE AND UPDATE THE USER DEVICE ARRAY
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [editUserIndex, setEditUserIndex] = useState<any>();
  const [userDataToEdit, setUserDataToEdit] = useState<IUserData>()
  const [userResponseSuccess, setUserResponseSuccess] = useState(false)
  const [userResponseError, setUserResponseError] = useState(false)
  const [userResponseMessage, setUserResponseMessage] = useState<string | undefined>("")


  // INITIALIZE DEVICE HERE
  useEffect(() => {
    setUserDevice(dataDevice)
    return () => {
      setUserDevice([]); // Reset or clear the state here
    };
  }, [dataDevice])


  useEffect(() => {
    setUserListing(dataUser)
  }, [dataUser])

  useEffect(() => {
    dispatch(getCustomerById(companyId))
    return () => {
      // Dispatch an action to reset or clear the state as needed
      dispatch(resetCustomerById());
    };
  }, [dispatch, companyId])


  useEffect(() => {
    dispatch(getDevicesById(companyId))
    return () => {
      // Dispatch an action to reset or clear the state as needed
      dispatch(resetDeviceById());
    };
  }, [dispatch])

  // GET ALL USERS RELATED TO ID:
  useEffect(() => {
    dispatch(fetchUser(companyId))
  }, [dispatch])


  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimeout(() => {
      const searchTerm = e.target.value.toLowerCase();
      const searchUserRes = dataUser.filter((item: any) => {
        const name = item.username.toLowerCase(); // Assuming 'name' is the property name containing the customer's name
        return name.includes(searchTerm);
      });

      if (searchUserRes.length > 0) {
        setUserListing(searchUserRes);
      } else {
        setUserListing([]);
      }
    }, 2000);
  }





  const handleAddUser = async () => {
    if (newData.username && newData.email && newData.password && newData.designation) {
      if ((newData.isSupervisor === false) && !newData?.userDevices?.length) {
        setUserResponseError(true)
        setUserResponseMessage("Please select Device")
        return
      }
      const userEmail = newData?.email;
      const userEmailRegexString = new RegExp('[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}');
      const userEmailRegexIsValid = userEmailRegexString.test(userEmail);
      if (!userEmailRegexIsValid) {
        setUserResponseError(true)
        setUserResponseMessage("Enter Valid Email")
      } else {
        const userData: IUserData = {
          _id: "",
          // userId: newData.userId,
          username: newData.username,
          email: newData.email,
          password: newData.password,
          designation: newData.designation,
          isSupervisor: newData.isSupervisor,
          rights: newData.rights,
          userDevices: newData.userDevices,
          isActive: newData.isActive,
          companyId: companyId
        }

        //HITTING THE API HERE FOR ADD NEW USER:
        try {
          const response = await userServices.addUser(userData)
          setNewData(initialUser);
          setUserResponseSuccess(true)
          setUserResponseMessage(response?.data?.message)
          dispatch(fetchUser(companyId))
          onCloseAdd()
        } catch (error: any) {
          const errorMessage = error.response?.data?.message || 'An error occurred';
          setUserResponseError(true)
          setUserResponseMessage(errorMessage)
        }
      }
    } else {
      setUserResponseError(true)
      setUserResponseMessage("All feilds are required.")
    }
  };




  // HANDLE EDIT SECTION: 
  const handleEditUser = async (index: number) => {
    if (userDataToEdit?.username && userDataToEdit?.email && userDataToEdit?.password && userDataToEdit?.designation) {
      if ((!userDataToEdit?.isSupervisor) && !userDataToEdit?.userDevices?.length) {
        setUserResponseError(true)
        setUserResponseMessage("Please select Device")
        return
      }
      const userEmail = userDataToEdit?.email;
      const userEmailRegexString = new RegExp('[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}');
      const userEmailRegexIsValid = userEmailRegexString.test(userEmail);

      if (!userEmailRegexIsValid) {
        setUserResponseError(true)
        setUserResponseMessage("Enter Valid Email")
      } else {
        const userEditId = userDataToEdit?._id;

        try {
          // API HIT FOR UPDATE THE USER DATA:
          const response = await userServices.updateUser(userDataToEdit as IUserData, userEditId as any);
          setUserResponseSuccess(true)
          setUserResponseMessage(response?.data?.message)
          onCloseEdit()

          // if (userDataToEdit?.isSupervisor) {
          //   const updatedEditUserData = {
          //     ...userDataToEdit,
          //     userDevices: [] // Create a new array for userDevices
          //   };
          //   const response = await userServices.updateUser(updatedEditUserData as IUserData, userEditId as any);
          //   setUserResponseSuccess(true)
          //   setUserResponseMessage(response?.data?.message)
          //   onCloseEdit()
          // } else {
          //   const response = await userServices.updateUser(userDataToEdit as IUserData, userEditId as any);
          //   setUserResponseSuccess(true)
          //   setUserResponseMessage(response?.data?.message)
          //   onCloseEdit()
          // }
          dispatch(fetchUser(companyId))
        } catch (error: any) {
          const errorMessage = error.response?.data?.message || 'An error occurred';
          setUserResponseError(true)
          setUserResponseMessage(errorMessage)
        }
      }
    } else {
      setUserResponseError(true)
      setUserResponseMessage("All feilds are required.")
    }
  }

  // HANDLE DRAWER EDIT:
  const handleDrawerEdit = async (index: number) => {
    onOpenEdit()
    setNewData(initialUser)
    setEditUserIndex(index)

    const editUserData: IUserData | undefined = userListing.find((value: any, UserIndex) => {
      return UserIndex === index
    })

    if (editUserData) {
      setUserDataToEdit(editUserData);
      setSelectedDevices(editUserData?.userDevices)
    } else {
      setUserDataToEdit(undefined);
    }
  };

  // MANAGE THE ON CHANGE STATE FOR DEVICE EDIT SECTION
  const handleOnChangeEditUser = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    if (userDataToEdit) {
      // PARSE THE STATUS AS A BOOLEAN
      const { name, value } = e.target;
      const parsedValue = (name === "isSupervisor" || name === "isActive") ? (value === "1" ? true : false) : value;
      setUserDataToEdit({
        ...userDataToEdit,
        [name]: parsedValue
      });
    } else {
      console.error('Device data for edit is undefined.');
    }
  };


  const handleNewData = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    const { name, value } = e.target;
    // PARSE THE STATUS AS A BOOLEAN
    const parsedValue = name === "isSupervisor" ? (value === "1" ? true : false) : value;
    setNewData(prevData => ({
      ...prevData,
      [e.target.name]: parsedValue
    }));
  }


  const handleMultiSelecDevices = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    setSelectedDevices(prevDevices => {
      const updatedDevices = checked ? [...prevDevices, value] : prevDevices.filter(device => device !== value);
      // Update newData with the updatedDevices
      setNewData(prevData => ({
        ...prevData,
        userDevices: updatedDevices
      }));
      return updatedDevices;
    });
  };




  // const [editSelectedDevices, setEditSelectedDevices] = useState<string[]>(userDataToEdit?.userDevices || []);
  const handleEditMultiSelectDevices = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    setSelectedDevices(prevDevices => {
      const updatedDevices = checked ? [...prevDevices, value] : prevDevices.filter(device => device !== value);
      // Update userDataToEdit with the updatedDevices
      setUserDataToEdit(prevData => prevData ? { ...prevData, userDevices: updatedDevices } : prevData);
      return updatedDevices;
    });
  };

  // FOR SUPERVISOR AND DEVICE SECTION EDIT:
  useEffect(() => {
    if (userDataToEdit?.isSupervisor) {
      setShowDevices(false);
      setUserDevice([])
      setSelectedDevices([])
      setUserDataToEdit({
        ...userDataToEdit,
        userDevices: [],
      })
    } else {
      setShowDevices(true);
      setUserDevice(dataDevice)
    }
  }, [userDataToEdit?.isSupervisor])

  // FOR SUPERVISOR AND DEVICE SECTION ADD:
  useEffect(() => {
    if (newData.isSupervisor) {
      setShowDevices(false);
      setUserDevice([])
      setSelectedDevices([])
      setNewData({
        ...newData,
        userDevices: [],
      });
    } else {
      setShowDevices(true);
      setUserDevice(dataDevice)
    }
  }, [newData.isSupervisor])


  const handleAddRow = () => {
    return (
      <Tr>
        <Td
          colSpan={8}
          pt={"20px"}
          textAlign={"center"}
          fontSize={"15px"}
          color={'teal'}
          fontWeight={"bold"}
          opacity={"0.7"}
        >No user found add user</Td>
        {/* <Td>
          <Tooltip label="Add" hasArrow aria-label='A tooltip' bg='teal.500' color='white'>
            <Icon as={AddIcon} mx={3} _hover={{ cursor: "pointer" }} boxSize={4} onClick={() => handleDrawerAdd()} />
          </Tooltip>
        </Td> */}
      </Tr>
    );
  };

  // DRRAWER FOR ADDING THE USER
  const handleDrawerAdd = () => {
    onOpenAdd()
    setNewData(initialUser)
  }
  return (
    <>
      {userResponseSuccess && <ShowToast message={userResponseMessage} resStatus="success" setFunction={setUserResponseSuccess} />}
      {userResponseError && <ShowToast message={userResponseMessage} resStatus="error" setFunction={setUserResponseError} />}

      {isLoading ? <Loader /> : <CustomTable
        newData={newData}
        title="IOT Dashboard"
        subtitleCustomer="Customer Management"
        subtitleDevice="Device Management"
        subtitleUser="User Management"
        pageType="users"
        handleDrawerAdd={handleDrawerAdd}
        headers={[
          // "ID",
          "Name",
          "Email ",
          "Password",
          "Designation",
          "Supervisor",
          // "rights (Optional)",
          "User Devices",
          "isActive",
          "Action"
        ]}
        companyId={companyId}
        handleSearch={handleSearch}
        dataCustomer={dataCustomer}
      >
        <Tbody>
          {userListing.map((item, index) => {
            const backgroundColor = item?.isActive ? "#b3ffb3" : "#ffc2b3"; // Determine background color based
            return (
              <>
                <Tr key={index}>
                  <Td>
                    {item.username}
                  </Td>
                  <Td>
                    {item.email}
                  </Td>
                  <Td>
                    <Input
                      disabled
                      size={"sm"}
                      width={"170px"}
                      fontSize={"sm"}
                      name="password"
                      type="password"
                      value={item.password}
                    // onChange={(e) => handleChange(e, index)}
                    />
                  </Td>
                  <Td>
                    {item.designation}
                  </Td>
                  <Td>
                    {item.isSupervisor ? "Yes" : "No"}
                  </Td>

                  <Td>
                    {
                      item.isSupervisor ? " " :
                        <>
                          {dataDevice.filter(device => item.userDevices.includes(device._id))
                            .map(filteredDevice => (
                              <Text key={filteredDevice._id}>{filteredDevice.deviceName}</Text>
                            ))}
                        </>
                    }
                  </Td>
                  <Td bgColor={backgroundColor}>
                    {item?.isActive ? "Active" : "InActive"}
                  </Td>
                  <Td>
                    <Tooltip label="Update" hasArrow aria-label='A tooltip' bg='teal.500' color='white'>
                      <EditIcon mx={2} _hover={{ cursor: "pointer" }} boxSize={4} onClick={() => handleDrawerEdit(index)} />
                    </Tooltip>
                  </Td>
                </Tr>
              </>
            )
          })}
          {
            userListing && userListing?.length === 0 && handleAddRow()
          }
          {/* {handleAddRow()} */}
        </Tbody>
      </CustomTable>}


      {/* DRAWER FOR ADD USER */}
      <Drawer
        isOpen={isOpenAdd}
        placement='right'
        onClose={onCloseAdd}
        size={"lg"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottom={"1px solid #DADADA"} boxShadow={"3px 6px 8px 0px #DADADA"}>
            Add User
          </DrawerHeader>

          <DrawerBody>
            <FormControl isRequired mt={"20px"}>
              <FormLabel fontSize={"sm"}>Name</FormLabel>
              <Input
                fontSize={"sm"}
                size={"sm"}
                placeholder="Enter user name"
                name="username"
                value={newData.username}
                onChange={(e) => handleNewData(e)}
              />
            </FormControl>

            <FormControl isRequired mt={"20px"}>
              <FormLabel fontSize={"sm"}>Email</FormLabel>
              <Input
                fontSize={"sm"}
                size={"sm"}
                placeholder="Enter user email"
                name="email"
                value={newData.email}
                onChange={(e) => handleNewData(e)}
              />
            </FormControl>


            <FormControl isRequired mt={"20px"}>
              <FormLabel fontSize={"sm"}>Password</FormLabel>
              <Input
                fontSize={"sm"}
                size={"sm"}
                type="password"
                placeholder="Enter user password"
                name="password"
                value={newData.password}
                onChange={(e) => handleNewData(e)}
              />
            </FormControl>

            <FormControl isRequired mt={"20px"}>
              <FormLabel fontSize={"sm"}>Designation</FormLabel>
              <Input
                fontSize={"sm"}
                size={"sm"}
                placeholder="Enter user designation"
                name="designation"
                value={newData.designation}
                onChange={(e) => handleNewData(e)}
              />
            </FormControl>

            <FormControl isRequired mt={"20px"}>
              <FormLabel fontSize={"sm"}>Supervisor</FormLabel>
              <Select
                fontSize={"sm"}
                size={"sm"}
                // placeholder="Select Supervisor"
                name="isSupervisor"
                value={newData.isSupervisor ? "1" : "0"}
                onChange={(e) => handleNewData(e)}
              >
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </Select>
            </FormControl>

            {
              showDevices &&
              <FormControl isRequired mt={"20px"}>
                <FormLabel fontSize={"sm"}>Devices</FormLabel>
                <CheckboxGroup colorScheme='green' value={selectedDevices}>
                  {
                    userDevice && userDevice.map((data: any, index: number) =>
                      <Stack key={index} spacing={[1, 5]} mt={2} direction={['column', 'row']}>
                        <Checkbox key={index} value={data._id} size={"sm"} onChange={(e) => handleMultiSelecDevices(e)}>{data.deviceName}</Checkbox>
                      </Stack>
                    )
                  }
                </CheckboxGroup>
              </FormControl>
            }

            <Button mt={"20px"} size={"sm"} fontSize={"12px"} colorScheme="teal" onClick={() => handleAddUser()}> Add </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* DRAWER FOR EDIT USER */}
      <Drawer
        isOpen={isOpenEdit}
        placement='right'
        onClose={onCloseEdit}
        size={"lg"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottom={"1px solid #DADADA"} boxShadow={"3px 6px 8px 0px #DADADA"}>
            Update User
          </DrawerHeader>
          <DrawerBody>

            <FormControl isRequired mt={"20px"}>
              <FormLabel fontSize={"sm"}>Name</FormLabel>
              <Input
                fontSize={"sm"}
                size={"sm"}
                placeholder="Enter user name"
                name="username"
                value={userDataToEdit?.username}
                onChange={(e) => handleOnChangeEditUser(e, editUserIndex)}
              />
            </FormControl>

            <FormControl isRequired mt={"20px"}>
              <FormLabel fontSize={"sm"}>Email</FormLabel>
              <Input
                isDisabled
                fontSize={"sm"}
                size={"sm"}
                placeholder="Enter user email"
                name="email"
                value={userDataToEdit?.email}
                onChange={(e) => handleOnChangeEditUser(e, editUserIndex)}
              />
            </FormControl>

            <FormControl isRequired mt={"20px"}>
              <FormLabel fontSize={"sm"}>Password</FormLabel>
              <Input
                isDisabled
                fontSize={"sm"}
                size={"sm"}
                type="password"
                placeholder="Enter user password"
                name="password"
                value={userDataToEdit?.password}
                onChange={(e) => handleOnChangeEditUser(e, editUserIndex)}
              />
            </FormControl>

            <FormControl isRequired mt={"20px"}>
              <FormLabel fontSize={"sm"}>Designation</FormLabel>
              <Input
                fontSize={"sm"}
                size={"sm"}
                placeholder="Enter user designation"
                name="designation"
                value={userDataToEdit?.designation}
                onChange={(e) => handleOnChangeEditUser(e, editUserIndex)}
              />
            </FormControl>

            <FormControl isRequired mt={"20px"}>
              <FormLabel fontSize={"sm"}>Supervisor</FormLabel>
              <Select
                fontSize={"sm"}
                size={"sm"}
                name="isSupervisor"
                value={userDataToEdit?.isSupervisor ? "1" : "0"}
                onChange={(e) => handleOnChangeEditUser(e, editUserIndex)}
              >
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </Select>
            </FormControl>

            {
              showDevices &&
              <FormControl isRequired mt={"20px"}>
                <FormLabel fontSize={"sm"}>Devices</FormLabel>
                <CheckboxGroup colorScheme='green' value={selectedDevices} >
                  {
                    userDevice && userDevice.map((data: any, index: number) =>
                      <Stack key={index} spacing={[1, 5]} mt={2} direction={['column', 'row']}>
                        <Checkbox
                          key={index}
                          value={data._id}
                          size={"sm"}
                          isChecked={selectedDevices.includes(data._id)}
                          onChange={handleEditMultiSelectDevices}
                        >{data.deviceName}</Checkbox>
                      </Stack>
                    )
                  }
                </CheckboxGroup>
              </FormControl>
            }

            <FormControl isRequired mt={"20px"}>
              <FormLabel>isActive</FormLabel>
              <Select
                fontSize={"sm"}
                // placeholder="isActive"
                size={"sm"}
                name="isActive"
                value={userDataToEdit?.isActive ? "1" : "0"}
                onChange={(e) => handleOnChangeEditUser(e, editUserIndex)}
              >
                <option value={1}>Active</option>
                <option value={0}>Inactive</option>
              </Select>
            </FormControl>

            <Button mt={"20px"} size={"sm"} fontSize={"12px"} colorScheme="teal" onClick={() => handleEditUser(editUserIndex)}> Update </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

    </>
  );
};






export default UserList;
