import React from "react";
import GaugeComponent from 'react-gauge-component'

interface ForgingMeterProps {
    minRange: number;
    maxRange: number;
    currentValue: number;
  }

const ForgingMeter = ({ minRange, maxRange, currentValue }: ForgingMeterProps) => {
    return (
        <>
            <GaugeComponent
                type="semicircle"
                style={{ width: "400px" }}
                arc={{
                    width: 0.15,
                    padding: 0.007,
                    cornerRadius: 2,
                    // gradient: true,
                    subArcs: [
                        {
                            limit: 400,
                            color: '#EA4228',
                            showTick: true,
                            tooltip: {
                                text: 'Too low temperature!'
                            },
                            // onClick: () => console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA"),
                            // onMouseMove: () => console.log("BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB"),
                            // onMouseLeave: () => console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC"),
                        },
                        {
                            limit: 500,
                            color: '#EA4228',
                            showTick: true,
                            tooltip: {
                                text: 'Too low temperature!!'
                            }
                        },
                        {
                            limit: 600,
                            color: '#EA4228',
                            showTick: true,
                            tooltip: {
                                text: 'Low temperature!'
                            }
                        },
                        {
                            limit: 700,
                            color: 'green',
                            showTick: true,
                            tooltip: {
                                text: 'Low temperature!'
                            }
                        },
                        {
                            limit: 800,
                            color: 'green',
                            showTick: true,
                            tooltip: {
                                text: 'Low temperature!'
                            }
                        },
                        {
                            limit: 900,
                            color: 'green',
                            showTick: true,
                            tooltip: {
                                text: 'High temperature!'
                            }
                        },
                        {
                            limit: 1000,
                            color: 'green',
                            showTick: true,
                            tooltip: {
                                text: 'High temperature!'
                            }
                        },
                        {
                            limit: 1100,
                            color: 'green',
                            showTick: true,
                            tooltip: {
                                text: 'High temperature!'
                            }
                        },
                        {
                            limit: 1200,
                            color: 'green',
                            showTick: true,
                            tooltip: {
                                text: 'Too high temperature!'
                            }
                        },
                        {
                            limit: 1300,
                            color: '#EA4228',
                            showTick: true,
                            tooltip: {
                                text: 'Too high temperature!'
                            }
                        },
                        {
                            limit: 1400,
                            color: '#EA4228',
                            showTick: true,
                            tooltip: {
                                text: 'Too high temperature!'
                            }
                        },
                        {
                            limit: 1500,
                            color: '#EA4228',
                            showTick: true,
                            tooltip: {
                                text: 'Too high temperature!'
                            }
                        },
                    ]
                }}
                pointer={{
                    color: '#345243',
                    length: 0.80,
                    width: 10,
                    // elastic: true,
                }}
                labels={{
                    valueLabel: { formatTextValue: value => value + 'ºC' },
                    // tickLabels: {
                    //     type: 'outer',
                    //     ticks: [
                    //         { value: 13 },
                    //         { value: 22.5 },
                    //         { value: 32 }
                    //     ],
                    // }
                }}

                value={currentValue}
                minValue={minRange}
                maxValue={maxRange}
            />
        </>
    )
}

export default ForgingMeter