import React from 'react';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MenuItem } from '@chakra-ui/react';
// @ts-ignore
import styles from "../assets/module/global.module.css";
const Logout: React.FC<any> = ({ type }) => {
    const navigate = useNavigate();
    const handleLogOut = () => {
        localStorage.clear()
        navigate("/login");
    }
    return (
        <>
            {
                type === "forging" ?
                    <MenuItem as='button'
                        // href='#' 
                        fontSize={"sm"}
                        fontWeight={"bold"}
                        color={"#fff"}
                        bgColor={"#3b5998"}
                        _hover={{
                            bgColor: "#72A0C1",
                            opacity: 0.9,
                        }}
                        onClick={() => handleLogOut()}
                    >
                        Log out
                    </MenuItem>
                    : <Button className={styles.tableBtn} colorScheme="teal" size="sm" onClick={() => handleLogOut()}>
                        Log out
                    </Button>
            }

        </>
    )
}

export default Logout