import React, { useEffect, ChangeEvent } from 'react'
import { useState } from 'react';
import {
    Button,
    VStack,
    Thead,
    Table,
    Tr,
    Th,
    Tbody,
    Td,
    Tooltip,
    Select,
    Box,
    Heading,
    CheckboxGroup,
    Checkbox,
    Stack,
} from '@chakra-ui/react';
import { getMyCompanyUsers, updateUserdeviceAccess } from '../Redux/userSlice';
import { useAppDispatch, useAppSelector } from '../Redux/hooks';
// @ts-ignore
import styles from '../assets/module/User.module.css';
import { getUserCompanyDevices } from '../Redux/deviceSlice';
import Loader from './loader';
import ShowToast from './ShowToast';
import { getMyCompanyData } from '../Redux/customerSlice';

const User: React.FC<{ buttonType: string; handleClose:any; setButtonType: React.Dispatch<React.SetStateAction<string>>; }> = ({ buttonType, setButtonType, handleClose }) => {
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [userDetail, setUserDetail] = useState<any[]>([]);
    const [userDevice, setUserDevice] = useState<any>([])
    const dispatch = useAppDispatch();
    const { dataUser, isLoading, error } = useAppSelector((state) => state.user)
    const { dataDevice } = useAppSelector((state) => state.device)
    const [isError, setIsError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    // CALL API FOR GET MY COMPANY USERS
    useEffect(() => {
        dispatch(getMyCompanyUsers())
    }, [dispatch])

    // SETTING USER DETAIL HERE:
    useEffect(() => {
        setUserDetail(dataUser)
    }, [dataUser])



    useEffect(() => {
        setButtonType(buttonType)
    }, [])


    const handleUserDetail = (index: number) => {
        const user = userDetail[index]
        setSelectedUser(user); // Set the selected user
    }

    // SET DEVICES
    useEffect(() => {
        setUserDevice(dataDevice)
    }, [dataDevice])

    return (
        <>
            {
                selectedUser ? <UserDetailComponent
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    userDevice={userDevice}
                    isError={isError}
                    errorMessage={errorMessage}
                    setIsError={setIsError}
                    setErrorMessage={setErrorMessage}
                    isSuccess={isSuccess}
                    setIsSuccess={setIsSuccess}
                    handleClose={handleClose}
                /> :
                    <Box>
                        <Heading className={styles.headingUserListing} size="lg" mb={4}>
                            {/* User Listing */}
                        </Heading>

                        {/* <VStack spacing={4} align="stretch" width="100%"> */}
                        <Table className={styles.maintable} variant='simple' colorScheme='gray'>
                            <Thead>
                                <Tr>
                                    <Th sx={{ textTransform: 'none', fontSize: "14px" }}>
                                        <Tooltip label="User Name" hasArrow aria-label='A tooltip' bg='teal.500' color='white'>
                                            User Name
                                        </Tooltip>
                                    </Th>
                                    <Th sx={{ textTransform: 'none', fontSize: "14px" }}>
                                        &nbsp;
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {isLoading ? ( // Conditionally render Loader if isLoading is true
                                    <Tr>
                                        <Td colSpan={2} style={{ textAlign: 'center', borderColor: 'white' }}>
                                            <Loader />
                                        </Td>
                                    </Tr>
                                ) : 
                                    
                                
                                    userDetail && userDetail?.length > 0 && userDetail?.map((userValue, index) => (
                                        <Tr key={index} sx={{ '&:hover': { backgroundColor: 'gray.100' } }}>
                                            <Td>{userValue.username}</Td>
                                            <Td>
                                                <Button className={styles.tableBtn} colorScheme="teal" onClick={() => handleUserDetail(index)}>
                                                    user Detail
                                                </Button>
                                            </Td>
                                        </Tr>
                                    ))}
                            </Tbody>
                        </Table>
                        {/* </VStack> */}
                    </Box>
            }
        </>
    );
}

const UserDetailComponent: React.FC<any> = ({ selectedUser, 
    setSelectedUser, 
    userDevice, 
    isError, 
    setIsError, 
    errorMessage, 
    setIsSuccess,
    isSuccess,
    handleClose,
    setErrorMessage }: any) => {
    const dispatch = useAppDispatch();
    const [userSelectedId, setUserSelectedId] = useState()

    useEffect(() => {
        setUserSelectedId(selectedUser?._id)
    }, [selectedUser])

    const [selectedDevices, setSelectedDevices] = useState<string[]>(selectedUser?.userDevices);
    // Handle checkbox changes
    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {    
        const { value, checked } = e.target;
        if (checked) {
            setSelectedDevices([...selectedDevices, value]);
        } else {
            setSelectedDevices(prevDevices => prevDevices.filter(device => device !== value));
        }
    };

    const handleUpload = () => {
        // HIT API FOR UPDATING THE DEVICE ASSOCIATED TO THE USER:
        if (selectedDevices.length === 0) {
            setIsError(true)
            setErrorMessage("Please select device")
            return
        } else {
            const dataForApi = {
                deviceList: selectedDevices,
                user: userSelectedId
            }
            dispatch(updateUserdeviceAccess(dataForApi))
            // dispatch(getMyCompanyUsers())
            setIsSuccess(true)
            setErrorMessage("Device updated successfully")
            handleClose()
        }
    }

    const handleBack = () => {
        setSelectedUser(null)
    }


    return (

        <>
            {isError && errorMessage && <ShowToast message={errorMessage} resStatus="error" setFunction={setIsError} />}
            {isSuccess && errorMessage && <ShowToast message={errorMessage} resStatus="success" setFunction={setIsSuccess} />}
            <Box p={4}>
                <Heading as="h2" size="lg" mb={4}>
                    User Detail
                    <Button style={{ float: "right" }} className={styles.tableBtn} colorScheme="teal" onClick={() => handleBack()}>
                        Back
                    </Button>
                </Heading>

                <VStack spacing={4} align="stretch" width="100%">
                    <Table variant='simple' className={styles.maintable} colorScheme='gray'>
                        <Thead>
                            <Tr sx={{ '&:hover': { backgroundColor: 'gray.100' } }}>
                                <Th sx={{ textTransform: 'none', fontSize: "14px", color: "teal" }}>
                                    {/* <Tooltip label="User Name" hasArrow aria-label='A tooltip' bg='teal.500' color='white'> */}
                                    User Name
                                    {/* </Tooltip> */}
                                </Th>
                                <Td>{selectedUser && selectedUser.username}</Td>
                            </Tr>

                            {/* <Tr sx={{ '&:hover': { backgroundColor: 'gray.100' } }}>
                                <Th sx={{ textTransform: 'none', fontSize: "14px", color: "teal" }}>
                                    <Tooltip label="User Id" hasArrow aria-label='A tooltip' bg='teal.500' color='white'>
                                    User Id
                                    </Tooltip>
                                </Th>
                                <Td>{selectedUser && selectedUser._id}</Td>
                            </Tr> */}

                            <Tr sx={{ '&:hover': { backgroundColor: 'gray.100' } }}>
                                <Th sx={{ textTransform: 'none', fontSize: "14px", color: "teal" }}>
                                    {/* <Tooltip label="User Email" hasArrow aria-label='A tooltip' bg='teal.500' color='white'> */}
                                    User Email
                                    {/* </Tooltip> */}
                                </Th>
                                <Td>{selectedUser && selectedUser.email}</Td>
                            </Tr>

                            <Tr sx={{ '&:hover': { backgroundColor: 'gray.100' } }}>
                                <Th sx={{ textTransform: 'none', fontSize: "14px", color: "teal" }}>
                                    {/* <Tooltip label="User Device" hasArrow aria-label='A tooltip' bg='teal.500' color='white'> */}
                                    User Device
                                    {/* </Tooltip> */}
                                </Th>
                                <Td>

                                    <CheckboxGroup colorScheme='green' value={selectedDevices}>
                                        {
                                            userDevice && userDevice.map((item: any, index: number) =>
                                                <Stack key={index} spacing={[1, 5]} mt={2} direction={['column', 'row']}>
                                                    <Checkbox
                                                        key={index}
                                                        value={item._id}
                                                        isChecked={selectedDevices.includes(item._id)}
                                                        onChange={(e) => handleCheckboxChange(e)}
                                                    >{item.deviceName}</Checkbox>
                                                </Stack>
                                            )
                                        }
                                    </CheckboxGroup>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>&nbsp;</Td>
                                <Td><Button className={styles.tableBtn} colorScheme="teal" onClick={() => handleUpload()}>
                                    Update
                                </Button></Td>
                            </Tr>
                        </Thead>
                    </Table>

                </VStack>
            </Box>
        </>
    )
}


export default User