// Firebase Cloud Messaging Configuration File. 
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID
};

initializeApp(firebaseConfig);

// const messaging = getMessaging();
let messaging;
try {
    messaging = getMessaging();
} catch (err) {
    console.error('Failed to initialize Firebase Messaging', err);
    alert('failed to subscribe to Firebase because: ', err.message)
}

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: `BP7_stnD3jvGuGq82oMwkooRforJy4_2FXbi_KK38h4lyKzuMsfszsHqSznpJPPB6XkJr4psv2h6ropitkEk4V0` })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        return currentToken
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        throw Error('please try again')
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      throw err
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
// export const onMessageListener = () =>
//   new Promise((resolve) => {    
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
//   });

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      if (Notification.permission === 'granted') {
        const { title, body, image } = payload.notification;
        const options = {
          body, 
          icon: image, 
          requireInteraction: true, 
          vibrate: [200, 100, 200],
        }
        new Notification(title, options);
      }
      resolve(payload);
    });
  });

  
