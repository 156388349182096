import React from 'react';
import { Outlet, Navigate } from "react-router-dom";


  
const UserPrivateRoutes = (props:any) => {
    let auth = localStorage?.getItem('token') ?  {'isLoggedIn': true} : {'isLoggedIn': false}
    let isSuperAdmin = localStorage?.getItem('isSuperAdmin');
    return(
        auth.isLoggedIn && isSuperAdmin === '0'? <Outlet/> : <Navigate to="/" />
    )
}

export default UserPrivateRoutes;