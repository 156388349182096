import React, { ReactEventHandler, useEffect, useState, useRef } from "react";
import * as customerServices from '../services/customerService';
import {
  Input,
  Tbody,
  Tr,
  Td,
  Select,
  Icon,
  Box,
  Tooltip,
  Image,
  Center,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  FormControl,
  FormLabel
} from "@chakra-ui/react";
import { TiUserAdd } from "react-icons/ti";
import { MdDeviceThermostat } from "react-icons/md";
import { Link, Link as ReactRouterLink } from 'react-router-dom';
import CustomTable from "../components/customTable";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import Loader from "../components/loader";
import { ICustomerData } from "../constants/customerTypes";
import { fetchCustomer, onChangeCustomer } from "../Redux/customerSlice";
import { ICRootState } from "../Redux/customerSlice";
import { useAppDispatch, useAppSelector } from '../Redux/hooks';
import ShowToast from "../components/ShowToast";


const initialCustomer: ICustomerData = {
  _id: "",
  customerId: "",
  customerName: "",
  customerEmail: "",
  inputDataOrder: "",
  displayDataOrder: "",
  notification: "",
  image: "",
  customerType: "",
  isActive: true,
  status: true,
};

const CustomerList: React.FC = (props) => {
  const IMAGE_URL_LOGO = process.env.REACT_APP_STORAGE_URL
  const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure()
  const { isOpen: isOpenAdd, onOpen: onOpenAdd, onClose: onCloseAdd } = useDisclosure()

  const [customerResponseSuccess, setCustomerResponseSuccess] = useState<boolean>(false)
  const [customerResponseError, setCustomerResponseError] = useState<boolean>(false)
  const [customerResponseMessage, setCustomerResponseMessage] = useState<string | undefined>()

  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch()
  const [newData, setNewData] = useState<ICustomerData>(initialCustomer);
  const { dataCustomer, isLoading } = useAppSelector((state: ICRootState) => state.customer);
  const [customerListing, setCustomerListing] = useState<any[]>([]);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string>()
  const [updatedImage, setUpadtedImage] = useState<File | null>(null);
  const [editCustomerIndex, setEditCustomerIndex] = useState<number>()
  const [customerDataToEdit, setCustomerDataToEdit] = useState<any>()

  // GET ALL CUSTOMERS:
  useEffect(() => {
    dispatch(fetchCustomer());
  }, [dispatch]);


  useEffect(() => {
    setCustomerListing(dataCustomer)
  }, [dataCustomer])


  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimeout(() => {
      const searchTerm = e.target.value.toLowerCase();
      const searchCustomerRes = dataCustomer.filter((item) => {
        const name = item.customerName.toLowerCase(); // Assuming 'name' is the property name containing the customer's name
        return name.includes(searchTerm);
      });

      if (searchCustomerRes.length > 0) {
        setCustomerListing(searchCustomerRes);
      } else {
        setCustomerListing([]);
      }
    }, 2000);
  }

  const handleAddCustomer = async () => {

    if (newData.customerName && newData.customerEmail && newData.customerType && newData.notification) {
      // VALIDATION USING REGULAR EXPRESSION:
      const customerEmail = newData.customerEmail;
      const customerEmailRegexString = new RegExp('[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}');
      const customerEmailRegexIsValid = customerEmailRegexString.test(customerEmail);
      if (!customerEmailRegexIsValid) {
        setCustomerResponseError(true)
        setCustomerResponseMessage("Enter Valid Email")
      } else {
        // HANDLE ADD CUSTOMER API:
        if (imageFile) {
          const customerData: ICustomerData = {
            _id: "",
            customerId: newData.customerId,
            customerName: newData.customerName,
            customerEmail: newData.customerEmail,
            inputDataOrder: newData.inputDataOrder,
            displayDataOrder: newData.displayDataOrder,
            image: imageFile,
            notification: newData.notification, // true
            customerType: newData.customerType,
            isActive: true,
            status: true,
          }
          try {
            const response = await customerServices.addCustomer(customerData)
            setNewData(initialCustomer);
            setCustomerResponseSuccess(true)
            setCustomerResponseMessage(response?.data?.message)
            dispatch(fetchCustomer())
            onCloseAdd()
            // Reset imageFile and imageUrl after successful submission
            if (inputRef.current) {
              inputRef.current.value = ""; // RESET INPUT FIELD VALUE
            }
            setImageFile(null);
          } catch (error: any) {
            const errorMessage = error.response?.data?.message || 'An error occurred';
            setCustomerResponseError(true)
            setCustomerResponseMessage(errorMessage)
          }
        } else {
          setCustomerResponseError(true)
          setCustomerResponseMessage("Upload logo image")
        }
      }
    } else {
      setCustomerResponseError(true)
      setCustomerResponseMessage("Please fill required fields")
    }
  };

  // MANGE DRAWER FOR EDIT THE PARTCULAR DATA OPEN , INITIALIZE, SET INDEX OF DATA AND FIND THE DATA TO SHOW FURTHUR FOR EDITING
  const handleDrawerEdit = (index: any) => {
    onOpenEdit()
    setEditCustomerIndex(index)
    const editCustomerData: ICustomerData | undefined = customerListing.find((value, CustIndex) => {
      return CustIndex === index
    })
    editCustomerData && setCustomerDataToEdit(editCustomerData);
  }

  const handleEditCustomer = async (index: number | undefined) => {
    if (customerDataToEdit?.customerName && customerDataToEdit?.customerEmail && customerDataToEdit?.customerType) {
      const customerEmail = customerDataToEdit.customerEmail;
      const customerEmailRegexString = new RegExp('[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}');
      const customerEmailRegexIsValid = customerEmailRegexString.test(customerEmail);
      if (!customerEmailRegexIsValid) {
        setCustomerResponseError(true)
        setCustomerResponseMessage("Enter Valid Email")
      } else {
        const custEditId = customerDataToEdit?._id;
        try {
          const response = await customerServices.updateCustomer(customerDataToEdit as ICustomerData, custEditId as any)
          setCustomerResponseSuccess(true)
          setCustomerResponseMessage(response?.data?.message)
          dispatch(fetchCustomer())
          onCloseEdit()
        } catch (error: any) {
          const errorMessage = error.response?.data?.message || 'An error occurred';
          setCustomerResponseError(true)
          setCustomerResponseMessage(errorMessage)
        }
      }
    } else {
      setCustomerResponseError(true)
      setCustomerResponseMessage("Please fill required fields")
    }
  };

  const handleEditImage = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number | undefined
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const newImages = [...customerListing]; // Create a copy of the array
      const file = e.target.files[0];
      if (!file.type.startsWith('image/')) {
        setCustomerResponseError(true)
        setCustomerResponseMessage("Please upload an image file")
        if (inputRef.current) {
          setImageFile(null)
          inputRef.current.value = ""; // Reset input field value
        }
        return;
      }

      // Update the specific customer's image in the copied array
      if (index) {
        newImages[index] = {
          ...newImages[index],
          image: file,
          isBrowsed: "yes"
        };
        // Update the state with the modified customerListing
        setCustomerDataToEdit(newImages[index]);
      }
    }
  };

  // MANAGE THE ON CHANGE STATE FOR USER EDIT SECTION
  const handleEditUserChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index?: number
  ) => {
    const { name, value } = e.target;
    // PARSE THE STATUS AS A BOOLEAN
    const parsedValue = name === "isActive" ? (value === "1" ? true : false) : value;
    if (customerDataToEdit) {
      setCustomerDataToEdit({
        ...customerDataToEdit,
        [name]: parsedValue
      });
    } else {
      console.error('Device data for edit is undefined.');
    }
  }

  const handleNewData = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setNewData({
      ...newData,
      [e.target.name]: e.target.value
    })
  }

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files && e.target.files.length > 0) {
      // Check if the selected file type is an image
      const file = e.target.files[0];
      if (!file.type.startsWith('image/')) {
        setCustomerResponseError(true)
        setCustomerResponseMessage("Please upload an image file")
        if (inputRef.current) {
          setImageFile(null)
          inputRef.current.value = ""; // Reset input field value
        }
        return;
      }
      setImageFile(file);
      setImageUrl(URL.createObjectURL(file));
    }
  }



  // OPENING THE DRAWER AND MAKE INITIAL STATE TO EMPTY FOR ADDING DEVICE
  const handleDrawerAdd = () => {
    onOpenAdd()
    setNewData(initialCustomer)
  }
  const handleAddRow = () => (
    <Tr key="new-row">
      <Td
        colSpan={10}
        pt={"20px"}
        textAlign={"center"}
        fontSize={"15px"}
        color={'teal'}
        fontWeight={"bold"}
        opacity={"0.7"}
      >
        No customer found add customer
      </Td>
    </Tr>
  );
  
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {customerResponseSuccess && (<ShowToast message={customerResponseMessage} resStatus="success" setFunction={setCustomerResponseSuccess} />)}
          {customerResponseError && (<ShowToast message={customerResponseMessage} resStatus="error" setFunction={setCustomerResponseError} />)}
          <CustomTable
            newData={newData}
            title="IOT Dashboard"
            subtitleCustomer="Customer Management"
            subtitleDevice="Device Management"
            subtitleUser="User Management"
            pageType="customer"
            handleDrawerAdd={handleDrawerAdd}
            headers={[
              'ID',
              'Name',
              'Email',
              'Input Data Order',
              'Display Data Order',
              'Notification',
              'Type',
              'logo Image',
              'isActive',
              'Action',
            ]}
            handleSearch={handleSearch}
          >
            <Tbody>
              {customerListing.map((customer, index) => {
                const backgroundColor = customer?.isActive ? "#b3ffb3" : "#ffc2b3"; // Determine background color based
                return (
                  <Tr key={index} opacity={"0.8"}>
                    <Td fontSize={"sm"}>
                      {customer?.customerId}
                    </Td>
                    <Td>
                      {customer?.customerName}
                    </Td>
                    <Td>
                      {customer?.customerEmail}
                    </Td>
                    <Td >
                      {customer?.inputDataOrder ? customer?.inputDataOrder : "deviceId#minTemp#currentTemp#maxTemp"}
                    </Td>
                    <Td>
                      {customer.displayDataOrder ? customer.displayDataOrder : "DISPLAY DATA ORDER"}
                    </Td>
                    <Td>
                      {customer?.notification ? "Yes" : "No"}
                    </Td>
                    <Td >
                      {customer?.customerType}
                    </Td>
                    <Td>
                      <Center>
                        <Image src={`${IMAGE_URL_LOGO}${customer?.customerLogo}`} alt="Preview" style={{ maxWidth: '30px', maxHeight: "30px" }} />
                      </Center>
                    </Td>
                    <Td bgColor={backgroundColor}>
                      {customer?.isActive ? "Active" : "InActive"}
                    </Td>
                    <Td >
                      <Tooltip
                        label="Update Customer"
                        hasArrow
                        aria-label="A tooltip"
                        color="white"
                        bg="teal.500"
                      >
                        <EditIcon
                          mx={2}
                          _hover={{ cursor: 'pointer' }}
                          boxSize={4}
                          onClick={() => handleDrawerEdit(index)}
                        />
                      </Tooltip>
                      <Tooltip

                        label="User(s)"
                        hasArrow
                        aria-label="A tooltip"
                        bg="teal.500"
                        color="white"
                      >
                        <Link to={`/admin/users/${customer._id}`}>
                          <Icon as={TiUserAdd} mx={2} boxSize={5} />
                        </Link>
                      </Tooltip>
                      <Tooltip
                        label="Device(s)"
                        hasArrow
                        aria-label="A tooltip"
                        bg="teal.500"
                        color="white"
                      >
                        <Link to={`/admin/devices/${customer._id}`}>
                          <Icon
                            as={MdDeviceThermostat}
                            _hover={{ cursor: 'pointer' }}
                            boxSize={5}
                          />
                        </Link>
                      </Tooltip>
                    </Td>
                  </Tr>
                )
              })}
              {
                customerListing && customerListing?.length === 0 && handleAddRow()
              }
              {/* {handleAddRow()} */}
            </Tbody>
          </CustomTable>

          {/* DRAWER FOR ADD SECTION  */}
          <Drawer
            isOpen={isOpenAdd}
            placement='right'
            onClose={onCloseAdd}
            size={"lg"}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader borderBottom={"1px solid #DADADA"} boxShadow={"3px 6px 8px 0px #DADADA"}>
                Add Customer
              </DrawerHeader>

              <DrawerBody>
                <FormControl isRequired mt={"20px"}>
                  <FormLabel>Name</FormLabel>
                  <Input
                    size={"sm"}
                    placeholder="Enter customer name"
                    name="customerName"
                    value={newData.customerName}
                    onChange={(e) => handleNewData(e)}
                  />
                </FormControl>

                <FormControl isRequired mt={"20px"}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    size={"sm"}
                    placeholder="Enter customer email"
                    name="customerEmail"
                    value={newData.customerEmail}
                    onChange={(e) => handleNewData(e)}
                  />
                </FormControl>

                <FormControl mt={"20px"}>
                  <FormLabel>Input data order</FormLabel>
                  <Input
                    size={"sm"}
                    placeholder="deviceId#minTemp#currentTemp#maxTemp"
                    name="inputDataOrder"
                    value={newData.inputDataOrder}
                    onChange={(e) => handleNewData(e)}
                  />
                </FormControl>


                <FormControl mt={"20px"}>
                  <FormLabel>Display data order</FormLabel>
                  <Input
                    size={"sm"}
                    placeholder="Display data order"
                    name="displayDataOrder"
                    value={newData.displayDataOrder}
                    onChange={(e) => handleNewData(e)}
                  />
                </FormControl>


                <FormControl isRequired mt={"20px"}>
                  <FormLabel>Notification</FormLabel>
                  <Select
                    size={"sm"}
                    placeholder="Select notification"
                    name="notification"
                    value={`${newData.notification}`}
                    onChange={(e) => handleNewData(e)}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Select>
                </FormControl>


                <FormControl isRequired mt={"20px"}>
                  <FormLabel>Type</FormLabel>
                  <Select
                    size={"sm"}
                    placeholder="Select type"
                    name="customerType"
                    value={newData.customerType}
                    onChange={(e) => handleNewData(e)}
                  >
                    <option value="forging">Forging</option>
                    <option value="galvanization">Galvanization</option>
                  </Select>
                </FormControl>


                <FormControl isRequired mt={"20px"}>
                  <FormLabel>Logo image</FormLabel>
                  <Input
                    ref={inputRef} // Assign the ref here
                    size={"sm"}
                    type="file"
                    accept="image/*"
                    multiple={false}
                    onChange={(e) => handleImage(e)}
                  />
                </FormControl>

                {imageFile ? (
                  <Image mt={"20px"} ml={"10px"} src={imageUrl} alt="Preview" style={{ maxWidth: '50px', maxHeight: "50px" }} />
                ) : null}
                <Button mt={"20px"} size={"sm"} fontSize={"12px"} colorScheme="teal" onClick={() => handleAddCustomer()}> Add </Button>
              </DrawerBody>
            </DrawerContent>
          </Drawer>


          {/* DRAWER FOR EDIT */}
          <Drawer
            isOpen={isOpenEdit}
            placement='right'
            onClose={onCloseEdit}
            size={"lg"}
          >
            <DrawerOverlay />
            <DrawerContent >
              <DrawerCloseButton />
              <DrawerHeader borderBottom={"1px solid #DADADA"} boxShadow={"3px 6px 8px 0px #DADADA"}>
                Update Customer
              </DrawerHeader>
              <DrawerBody>
                <FormControl isRequired mt={"20px"}>
                  <FormLabel>Name</FormLabel>
                  <Input
                    size={"sm"}
                    placeholder="Enter customer name"
                    name="customerName"
                    value={customerDataToEdit?.customerName}
                    onChange={(e) => handleEditUserChange(e, editCustomerIndex)}
                  />
                </FormControl>

                <FormControl isRequired mt={"20px"}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    size={"sm"}
                    placeholder="Enter customer email"
                    name="customerEmail"
                    value={customerDataToEdit?.customerEmail}
                    onChange={(e) => handleEditUserChange(e, editCustomerIndex)}
                  />
                </FormControl>


                <FormControl mt={"20px"}>
                  <FormLabel>Input data order</FormLabel>
                  <Input
                    size={"sm"}
                    placeholder="deviceId#minTemp#currentTemp#maxTemp"
                    name="inputDataOrder"
                    value={customerDataToEdit?.inputDataOrder}
                    onChange={(e) => handleEditUserChange(e, editCustomerIndex)}
                  />
                </FormControl>

                <FormControl mt={"20px"}>
                  <FormLabel>Display data order</FormLabel>
                  <Input
                    size={"sm"}
                    placeholder="Display data order"
                    name="displayDataOrder"
                    value={customerDataToEdit?.displayDataOrder}
                    onChange={(e) => handleEditUserChange(e, editCustomerIndex)}
                  />
                </FormControl>


                <FormControl isRequired mt={"20px"}>
                  <FormLabel>Notification</FormLabel>
                  <Select
                    size={"sm"}
                    name="notification"
                    value={customerDataToEdit?.notification}
                    onChange={(e) => handleEditUserChange(e, editCustomerIndex)}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Select>
                </FormControl>


                <FormControl isRequired mt={"20px"}>
                  <FormLabel>Type</FormLabel>
                  <Select
                    size={"sm"}
                    placeholder="Select type"
                    name="customerType"
                    value={customerDataToEdit?.customerType}
                    onChange={(e) => handleEditUserChange(e, editCustomerIndex)}
                  >
                    <option value="forging">Forging</option>
                    <option value="galvanization">Galvanization</option>
                  </Select>
                </FormControl>


                <FormControl isRequired mt={"20px"}>
                  <FormLabel>Logo image</FormLabel>
                  <Input
                    ref={inputRef} // Assign the ref here
                    size={"sm"}
                    type="file"
                    accept="image/*"
                    multiple={false}
                    onChange={(e) => handleEditImage(e, editCustomerIndex)}
                  />
                </FormControl>
                {
                  customerDataToEdit?.isBrowsed === "yes" ? (
                    <Image mt={"20px"} ml={"10px"} src={URL.createObjectURL(customerDataToEdit?.image)} alt="Preview" style={{ maxWidth: '50px', maxHeight: "50px" }} />
                  ) :
                    customerDataToEdit?.customerLogo ? (
                      <Image mt={"20px"} ml={"10px"} src={`${IMAGE_URL_LOGO}${customerDataToEdit?.customerLogo}`} alt="Preview" style={{ maxWidth: '50px', maxHeight: "50px" }} />
                    ) : null
                }
                <FormControl isRequired mt={"20px"}>
                  <FormLabel>isActive</FormLabel>
                  <Select
                    fontSize={"sm"}
                    size={"sm"}
                    name="isActive"
                    value={customerDataToEdit?.isActive ? "1" : "0"}
                    onChange={(e) => handleEditUserChange(e, editCustomerIndex)}
                  >
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </Select>
                </FormControl>

                <Button mt={"20px"} size={"sm"} fontSize={"12px"} colorScheme="teal" onClick={() => handleEditCustomer(editCustomerIndex)}> Update </Button>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )}
    </>
  );
};

export default CustomerList;
