import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as coustomerServices from '../services/customerService'
import apiInstance from "../config/api/axios";


export type ICRootState = {
    customer: CustomerState;
}

type CustomerState = {
    dataCustomer: any[];
    isLoading: boolean;
    error: null | string;
}

const initialState:CustomerState = {
    dataCustomer:[],
    isLoading: true,
    error: null,
}

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        getAllCustomer(state, action) {
            state.dataCustomer = action.payload;
        },
        resetCustomerById(state) {
            state.dataCustomer = [];
            state.isLoading = false;
            state.error = null;
          },
        onChangeCustomer(state, action) {
            const { index, fieldName, value } = action.payload;
            state.dataCustomer = state.dataCustomer.map((data, i) => {
                if (index === i) {
                    return {
                        ...data,
                        [fieldName]: value,
                    };
                }
                return data;
            });
        },
        statusLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        statusError(state, action) {
            state.error = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCustomerById.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(getCustomerById.fulfilled, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.dataCustomer = action.payload
        })
        .addCase(getCustomerById.rejected, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = action.payload;
        })
        .addCase(getMyCompanyData.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(getMyCompanyData.fulfilled, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.dataCustomer = action.payload
        })
        .addCase(getMyCompanyData.rejected, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = action.payload;
        })
    }
})

export default customerSlice.reducer;
export const { getAllCustomer, onChangeCustomer, resetCustomerById, statusLoading, statusError } = customerSlice.actions


//MIDDLE WARE
export const fetchCustomer = () =>
    async (dispatch: any) => {
        try {
            const response = await coustomerServices.customerList();
            dispatch(getAllCustomer(response.data.payload));
            dispatch(statusLoading(false));
            dispatch(statusError(null));
        } catch (error) {
            dispatch(statusError(error))
            console.error("Error fetching customer data:", error);
        }
    }


// API FOR GET DEVICE BY ID:
export const getCustomerById = createAsyncThunk("getCustomerById", async (comapanyId: string | undefined, thunkAPI): Promise<any> => {
    try {
        const response = await apiInstance.get(`/api/admin/company/${comapanyId}`);
        return response?.data?.payload
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
})



// API FOR GETTING COMAPNY DATA OF LOGGED IN USER:
export const getMyCompanyData = createAsyncThunk("getMyCompanyData", async (_, thunkAPI): Promise<any> => {
    try {
        const response = await apiInstance.get(`api/user/get-my-company`);
        return response?.data?.company
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
})
