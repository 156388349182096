import React, { useState } from 'react'
import * as authServices from '../services/authServices'
import {
    Box,
    Button,
    Container,
    FormControl,
    Input,
    Center,
    Text,
    FormLabel,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { IAdminLogin } from '../constants/loginTypes'
import { AxiosError } from 'axios'
import ShowToast from '../components/ShowToast'
const AdminLogin: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const [user, setUser] = useState<IAdminLogin>({
        email: '',
        password: '',
    })

    const [adminLoginMessage, setAdminLoginMessage] = useState(false)
    const [adminLoginResponseMessage, setAdminLoginResonseMessage] = useState<string | undefined>()
    const [adminLoginErrorMessage, setAdminLoginErrorMessage] = useState(false)
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true)
        try {
            const response = await authServices.adminLogIn(user)
            setAdminLoginMessage(true)
            setAdminLoginResonseMessage(response?.data?.message)
            setAdminLoginErrorMessage(true)
            setIsLoading(false)
            navigate("/admin/dashboard");
        } catch (error:AxiosError | any) {
            if (error?.response && error?.response.data && error?.response?.data.message) {
                setAdminLoginResonseMessage(error?.response.data.message)
                setAdminLoginErrorMessage(true)
                setIsLoading(false)
            } else {
                setAdminLoginResonseMessage("An error occurred")
                setIsLoading(false)
                console.error("Error fetching data:", error);
            }
        }
    };


    const handleLoginChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setUser({
            ...user,
            [event.target.name]: event.target.value,
        })
    }
    return (
        <>
            <Container maxW="md">
            {adminLoginMessage && <ShowToast message={adminLoginResponseMessage} resStatus='success' setFunction={setAdminLoginMessage}/>}
            {adminLoginErrorMessage && <ShowToast message={adminLoginResponseMessage} resStatus='error' setFunction={setAdminLoginErrorMessage}/>}
                <Center h="100vh">
                    <Box
                        p={8}
                        maxW="md"
                        borderWidth={1}
                        borderRadius={8}
                        boxShadow="lg"
                        borderColor="gray.200"
                    >
                        <Text fontSize="2xl" fontWeight="bold" mb={4}>IOT Admin Dashboard</Text>
                        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                            <FormControl id="username" isRequired>
                                <FormLabel>Username</FormLabel>
                                <Input
                                    size="md"
                                    id="Username"
                                    type="text"
                                    name="email"
                                    placeholder="Username"
                                    _placeholder={{ opacity: 1, color: 'teal' }}
                                    value={user.email}
                                    onChange={(event) => handleLoginChange(event)}
                                />
                            </FormControl>

                            <FormControl id="password" isRequired mt={4}>
                                <FormLabel>Password</FormLabel>
                                <Input
                                    size="md"
                                    id="password"
                                    type="password"
                                    name="password"
                                    placeholder="password"
                                    _placeholder={{ opacity: 1, color: 'teal' }}
                                    value={user.password}
                                    onChange={(event) => handleLoginChange(event)}
                                />
                            </FormControl>
                            <Button 
                                isLoading={isLoading}
                                loadingText='login'
                                colorScheme='teal'
                                type="submit" 
                                mt={4} 
                                w="100%"
                            >
                                Login
                            </Button>
                        </form>
                    </Box>
                </Center>
            </Container>
        </>
    )
}

export default AdminLogin