import React, { useState } from 'react'
import * as authServices from '../services/authServices'
import {
    Box,
    Button,
    Container,
    FormControl,
    Input,
    Center,
    Text,
    FormLabel,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { IAdminLogin } from '../constants/loginTypes'
import ShowToast from '../components/ShowToast'

const UserLogin: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [user, setUser] = useState<IAdminLogin>({
        email: '',
        password: '',
    })

    const [userLoginMessage, setUserLoginMessage] = useState(false)
    const [userLoginErrorMessage, setUserLoginErrorMessage] = useState(false)
    const [loginErrorMessage, setLoginErrorMessage] = useState()
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true)
        try {
            const response = await authServices.userLogIn(user);
            setUserLoginMessage(true)
            setLoading(false)
            navigate("/dashboard")
        } catch (error: any) {
            setUserLoginErrorMessage(true)
            setLoginErrorMessage(error?.response?.data?.message || error?.response?.data)
            setLoading(false)
            console.error("Error Login Data:", error);
        }
    };

    const handleLoginChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setUser({
            ...user,
            [event.target.name]: event.target.value,
        })
    }
    return (
        <>
            <Box w={"100%"} h={"100vh"} bgColor={"gray.100"}>
                <Container maxW="md" >
                    {userLoginMessage && <ShowToast message='Logged in successfully' resStatus='success' setFunction={setUserLoginMessage} />}
                    {userLoginErrorMessage && <ShowToast message={loginErrorMessage} resStatus='error' setFunction={setUserLoginErrorMessage} />}
                    <Center h="100vh">
                        <Box
                            pt={8}
                            pl={8}
                            pr={8}
                            pb={3}
                            maxW="md"
                            borderWidth={1}
                            borderRadius={8}
                            boxShadow="lg"
                            borderColor="gray.300"
                            bgColor={"#fff"}
                        >
                            <Text fontSize="2xl" fontWeight="bold" mb={4}>IoT Dashboard</Text>
                            <form onSubmit={handleSubmit}   >
                                <FormControl id="username" isRequired>
                                    <FormLabel >Username</FormLabel>
                                    <Input
                                        size="md"
                                        fontSize={"sm"}
                                        id="Username"
                                        type="text"
                                        name="email"
                                        placeholder="Username"
                                        _placeholder={{ opacity: 1, color: 'teal' }}
                                        value={user.email}
                                        onChange={(event) => handleLoginChange(event)}
                                    />
                                </FormControl>

                                <FormControl id="password" isRequired mt={4}>
                                    <FormLabel>Password</FormLabel>
                                    <Input
                                        fontSize={"sm"}
                                        size="md"
                                        id="password"
                                        type="password"
                                        name="password"
                                        placeholder="password"
                                        _placeholder={{ opacity: 1, color: 'teal' }}
                                        value={user.password}
                                        onChange={(event) => handleLoginChange(event)}
                                    />
                                </FormControl>

                                <Button
                                    size={"sm"}
                                    isLoading={loading}
                                    loadingText='login'
                                    colorScheme='teal'
                                    type="submit"
                                    mt={4}
                                    w="100%"
                                >
                                    Login
                                </Button>
                                <em><Text mt={"10px"}  fontSize={"15px"} textAlign={"right"} color={"gray"}>&copy; Mindtroniks Technologies</Text></em>
                            </form>
                        </Box>
                    </Center>
                </Container>
            </Box>
        </>
    )
}

export default UserLogin