import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiInstance from "../config/api/axios";
import * as jobService from "../services/jobService";
import { IJobData } from "../constants/jobTypes";
export type IUJobState = {
    user: {
        dataJob: IJobData[];
        isLoading: boolean;
        isError: boolean;
        isJobAdded: boolean;
        error: string | null;
        isJobUpdated: boolean;
    }
}

type JobState = {
    dataJob: IJobData[];
    isLoading: boolean;
    isError: boolean;
    isJobAdded: boolean;
    isJobUpdated: boolean;
    error: string | null;
}

const initialState: JobState = {
    dataJob: [],
    isLoading: false,
    isError: false,
    isJobAdded: false,
    isJobUpdated: false,
    error: null,
}


const jobSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {
        statusLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        statusError(state, action) {
            state.error = action.payload
        },
        statusJobIsUpdated(state, action) {
            // state.isJobUpdated = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addUserJob.pending, (state) => {
            state.isLoading = false;
            state.isJobAdded = false;
            state.error = null;
        })
            .addCase(addUserJob.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.isError = false;
                state.isJobAdded = true;
                state.error = null;
                state.dataJob.push(action.payload);
            })
            .addCase(addUserJob.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.isError = true;
                state.isJobAdded = false;
                state.error = action?.payload?.message;
            })
            .addCase(updateJob.pending, (state) => {
                state.isLoading = false;
                state.isJobUpdated = false;
                state.error = null;
            })
            .addCase(updateJob.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.isError = false;
                state.isJobUpdated = true;
                state.error = null;
                // state.dataJob.push(action.payload);
            })
            .addCase(updateJob.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.isError = true;
                state.isJobUpdated = false;
                state.error = action?.payload;
            })
            .addCase(getJobListing.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getJobListing.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.isError = false;
                state.dataJob = action.payload;
            })
            .addCase(getJobListing.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.isError = true;
                state.error = action?.payload;
            })
    }
})

export default jobSlice.reducer;
export const { statusLoading, statusError, statusJobIsUpdated } = jobSlice.actions



export const addUserJob = createAsyncThunk("addUserJob", async (data: any, thunkAPI): Promise<any> => {
    try {
        const response = await apiInstance.post(`/api/user/create-job`, data);
        return response?.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
})

// API FOR GET ALL JOB:
export const getJobListing = createAsyncThunk("getJobListing", async (date: any, thunkAPI): Promise<any> => {
    try {
        const response = await apiInstance.get(`/api/user/job-listing?date=${date}`)
        return response?.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
})

//API FOR GET UPDATED JOB:
// API FOR GET ALL JOB:
export const updateJob = createAsyncThunk("updateJob", async (data: any, thunkAPI): Promise<any> => {
    try {
        const response = await jobService.updateJob(data.jobId, data.job);
        thunkAPI.dispatch(getJobListing(data?.date)); // This dispatches getJobListing to update the state
        return response?.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
})


// export const updateJob = (jobId: string, data: IJobData) =>
//     async (dispatch: any) => {
//         try {
//             const response = await jobService.updateJob(jobId, data);
//             dispatch(getJobListing())
//             if (response?.status === 200) {
//                 dispatch(statusJobIsUpdated(true))
//             }

//             dispatch(statusLoading(false));
//             dispatch(statusError(null));
//         } catch (error) {
//             dispatch(statusError(error))
//             console.error("Error Updating Job:", error);
//         }
//     }