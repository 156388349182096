import React from 'react';
import { Navigate, Outlet } from 'react-router-dom'

const UserPublicRoutes = (props: any) => {
    let auth = localStorage?.getItem('token') ? { 'isLoggedIn': true } : { 'isLoggedIn': false }

    let isSuperAdmin = localStorage?.getItem('isSuperAdmin');
    if (isSuperAdmin === '1') {
        return(<Navigate to="/admin" />)
    }
    return auth.isLoggedIn ? <Navigate to="/dashboard" /> : <Outlet />
}
export default UserPublicRoutes;