import React, { useEffect, useState } from 'react'
import {
  Flex,
  Container,
  Center,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  Divider,
  HStack,
  Spacer
} from '@chakra-ui/react'
import ShowToast from '../components/ShowToast';
type UserProfileProps = {
  setShowProfile: (value: boolean) => void;
  showProfile: boolean;
};

const UserProfile: React.FC<UserProfileProps> = ({ setShowProfile, showProfile }) => {

  const [isInValid, setIsInValid] = useState(false)
  const [toastIsError, setToastIsError] = useState(false)
  const [toastErrorMessage, setToastErrorMessage] = useState("")

  const [profile, setProfile] = useState({
    name: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  })

  const handleProfileSave = () => {

    if (!(profile.currentPassword && profile.newPassword && profile.confirmPassword)) {
      setToastIsError(true)
      setToastErrorMessage("All fields required")
    } else if (profile.newPassword !== profile.confirmPassword) {
      setToastIsError(true)
      setToastErrorMessage("New Password and Confirm password not matched")
    } else {
      setToastIsError(true)
      setToastErrorMessage("all good..!")
      console.log(profile, "shail profile...")
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    profile.newPassword !== profile.confirmPassword ? setIsInValid(true) : setIsInValid(false)
  }, [profile?.newPassword, profile?.confirmPassword])

  return (
    <>
      <>
        {toastIsError && <ShowToast message={toastErrorMessage} resStatus="error" setFunction={setToastIsError} />}
        <Flex
          flexDirection={"row"}
          alignItems={"left"}
          width={"100%"}
          bgColor={"#fff"}
          overflow={"hidden"}
        >


          <Container bg={"#fff"}>
            <Flex
              flexDirection={"column"}
              alignItems={"center"}
              width={"100%"}
            >

              <Flex flexDirection={"column"} width={"100%"}>
                <Center>
                  <Text fontSize={'23px'} fontWeight={"bold"}>Profile</Text>
                </Center>
              </Flex>
             
              <Flex flexDirection={"column"} width={"100%"} mb={"3px"}>

                <FormControl mt={"5px"}>
                  <FormLabel ml={"5px"} fontSize={"sm"}>Name:</FormLabel>
                  <Input
                    border={"1px solid #E0E0E0"}
                    size={"sm"}
                    placeholder='Enter name'
                    textColor={"black"}
                    name='name'
                    value={profile.name}
                    onChange={(e) => handleChange(e)}
                    disabled
                  />
                </FormControl>

                <FormControl isRequired mt={"30px"}>
                  <FormLabel ml={"5px"} fontSize={"sm"}>Current Password</FormLabel>
                  <Input
                    border={"1px solid #E0E0E0"}
                    size={"sm"}
                    placeholder='Enter Current Password'
                    type='password'
                    name='currentPassword'
                    value={profile.currentPassword}
                    onChange={(e) => handleChange(e)}
                  />
                </FormControl>

                <FormControl isRequired mt={"30px"}>
                  <FormLabel ml={"5px"} fontSize={"sm"}>New Password</FormLabel>
                  <Input
                    border={"1px solid #E0E0E0"}
                    size={"sm"}
                    placeholder='Enter New Password'
                    type='password'
                    name='newPassword'
                    value={profile.newPassword}
                    onChange={(e) => handleChange(e)}
                  />
                </FormControl>

                <FormControl isRequired mt={"30px"}>
                  <FormLabel ml={"5px"} fontSize={"sm"}>Confirm Paassword</FormLabel>
                  <Input
                    border={"1px solid #E0E0E0"}
                    isInvalid={isInValid}
                    errorBorderColor='red.300'
                    type='password'
                    placeholder='Enter confirm password'
                    name='confirmPassword'
                    value={profile.confirmPassword}
                    onChange={(e) => handleChange(e)}
                  />
                </FormControl>



                <HStack>
                  <Button
                    colorScheme='facebook'
                    size='sm'
                    fontSize={"12px"}
                    mt={"30px"}
                    width={"60px"}
                    onClick={() => handleProfileSave()}
                  >
                    Save
                  </Button>
                  <Button
                    colorScheme='facebook'
                    fontSize={"12px"}
                    size='sm'
                    mt={"30px"}
                    width={"60px"}
                    onClick={() => setShowProfile(false)}
                  >
                    Back
                  </Button>
                </HStack>

              </Flex>
            </Flex>

          </Container>

        </Flex>

      </>
    </>
  )
}



export default UserProfile
