import React, { useEffect, useState, ChangeEvent, useRef } from 'react';

import {
    Box,
    Button,
    Heading,
    HStack,
    Spacer,
    VStack,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Thead,
    TableContainer,
    Table,
    Icon,
    Tr,
    Th,
    Tbody,
    Td,
    Tooltip,
    Flex,
    Image,
    Text,
    Link,
    Container,
    Divider,
    AbsoluteCenter,
    Center,
    IconButton,
    Input
} from '@chakra-ui/react';
import { AddIcon, EditIcon } from "@chakra-ui/icons";
// @ts-ignore
import styles from '../assets/module/galvanization.module.css';
import { SiMicrosoftexcel } from "react-icons/si";
import Loader from "../components/loader";
import Logout from "../components/Logout";
import ManagePartiesComponent from "../components/ManagePartiesComponent"
import JobComponent from "../components/JobComponent"
import User from "../components/User";
import { useAppDispatch, useAppSelector } from '../Redux/hooks';
import { addUserJob, getJobListing, updateJob } from '../Redux/jobSlice';
import { getUserCompanyDevices } from '../Redux/deviceSlice';
import ShowToast from '../components/ShowToast';
import { getPartyListing } from '../Redux/partySlice';
import { getMyCompanyData } from '../Redux/customerSlice';
import { HiRefresh } from "react-icons/hi";
import { useDownloadExcel } from "react-export-table-to-excel";
// @ts-ignore
import logo from '../../src/logo.png'

const Galvanization: React.FC = () => {
    const logoImage = process.env.REACT_APP_STORAGE_URL;
    const tableRef = useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [sidebarContent, setSidebarContent] = useState<React.ReactNode>(null);
    const [addJob, setAddJob] = useState<boolean>(true)
    const { dataJob, isLoading, isError, isJobAdded, isJobUpdated, error } = useAppSelector((state: any) => state.job)
    const { dataCustomer } = useAppSelector((state: any) => state.customer)
    const { dataDevice } = useAppSelector((state) => state.device)
    const [jobs, setJobs] = useState<any[]>([]);
    const dispatch = useAppDispatch();

    const { dataParty } = useAppSelector((state) => state.party)
    const [partyList, setPartyList] = useState<any[]>([])

    // setting state for toast messages
    const [jobIsError, setJobIsError] = useState<boolean>(false)
    const [validationError, setValidationError] = useState<any>(true)
    const [jobErrorMessage, setJobErrorMessage] = useState<string | null>(null)
    const [jobIsJobAdded, setJobIsJobAdded] = useState<boolean>(false)
    const [jobIsUpdated, setJobIsUpdated] = useState<boolean>(false)

    const [pageType, setPageType] = useState<string>("manageJobPage")
    const [buttonType, setButtonType] = useState<string>("manageJobPage")
    const [selectedDate, setSelectedDate] = useState('');

    //SETTING ALL PARTY LIST:
    useEffect(() => {
        setPartyList(dataParty)
    }, [dataParty])

    // SET THE DEFAULT DATE TO CURRENT DATE FOR SHOWING IN DATE FILTER
    useEffect(() => {
        const currentDate = new Date()?.toISOString()?.split('T')[0]; // YYYY-MM-DD format
        setSelectedDate(currentDate);
    }, []);

    // HANDLE DATE PICKER FOR SHOWING DEVICE DATA ACCORDING TO DATE:
    const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSelectedDate(e.target.value);
    }

    useEffect(() => {
        dispatch(getUserCompanyDevices())
    }, [dispatch])

    const handleButtonClick = (content: React.ReactNode, type: string) => {
        // GETTING ALL THE PARTIES API:
        setSidebarContent(content);
        setPageType(type);
        onOpen();
    };

    // GET JOBS RELATED TO USER AND DEVICES:
    useEffect(() => {
        dispatch(getJobListing(selectedDate))
        isSupervisor === '1' && dispatch(getUserCompanyDevices())
    }, [dispatch]);


    // GET ALL PARTY LIST API:
    useEffect(() => {
        dispatch(getPartyListing(localStorage.getItem("companyId")));
    }, [dispatch])



    // GET COMPANY DATA FOR USER LOGGED IN
    useEffect(() => {
        dispatch(getMyCompanyData());
    }, [dispatch])


    // SETTING THE JOBS FROM API:
    const [showSearchedJob, setShowSearchedJob] = useState<any>()

    useEffect(() => {
        // APPLY LOGIC FOR CONVERT ALL IDS TO THERE NAME FOR SEARCHING PURPOSE..!
        const getDeviceName = (deviceId: string) => {
            const device = dataDevice.find((d) => d._id === deviceId);
            return device ? device.deviceId : deviceId;
        };

        const getPartyName = (partyId: string) => {
            const party = dataParty.find((p) => p._id === partyId);
            return party ? party.partyName : partyId;
        };

        // Map through dataJob to update fields
        const searchDataArray = dataJob.map((data: any) => {
            return {
                ...data,
                deviceId: getDeviceName(data.deviceId),
                partyName: getPartyName(data.partyName),
                temperature: data.temperature?.toString(),
                weightBeforeGI: data.weightBeforeGI?.toString(),
                weightAfterGI: data.weightAfterGI?.toString(),
                requiredCoating: data.requiredCoating?.toString(),
                appliedCoating: data.appliedCoating?.toString(),
                coatingMicrons: data.coatingMicrons?.toString(),
                status: data.status === 1 ? "Active" : data.status === 0 ? "Hold" : "Completed"
            };
        });
        setShowSearchedJob(searchDataArray)
        setJobs(searchDataArray)
        getCurrentDateTime()
    }, [dataJob])



    // Function to handle the search/filter
    const handleDateSearch = () => {
        // Convert selectedDate to a Date object and strip time parts for comparison        
        const searchDate = new Date(selectedDate);
        searchDate.setHours(0, 0, 0, 0); // Set time to midnight for accurate date comparison

        // Ensure the selected date is valid
        if (isNaN(searchDate.getTime())) {
            console.error("Invalid date selected");
            return;
        }
        dispatch(getJobListing(searchDate))
        setSearchText("")
    };

    // ON REFRESH DATE SET TO CURRENT DATE AND SEARCH BOX EMPTY
    const refreshJob = () => {
        const now = new Date();
        // Optionally format the date. This example formats it as YYYY-MM-DD
        const formattedDate = now.toISOString().split('T')[0];
        dispatch(getJobListing(formattedDate))
        setSelectedDate(formattedDate)
        setSearchText("")
    }

    const [refreshTimeStamp, setRefreshTimeStamp] = useState<string>("")
    const getCurrentDateTime = () => {
        const now = new Date();
        const timeStamp = formatDateTime(now);
        setRefreshTimeStamp(timeStamp)

    }

    const formatDateTime = (date: Date): any => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0'); // 24-hour format
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${day}-${month}-${year} ${hours}:${minutes}`
    }

    const handleNewJobSubmit = (job: any) => {
        setJobs((prevJobs) => {
            if (job.index !== undefined) {
                if (!(job?.deviceId && job?.partyName && job?.materialName && job?.materialSize && job?.temperature && job?.requiredCoating)) {
                    setJobIsError(true)
                    setJobErrorMessage("Please fill required field.")
                    setValidationError(true);
                    return prevJobs; // Return previous jobs if validation fails
                } else {
                    const updatedJobs = [...prevJobs];
                    updatedJobs[job.index] = job;

                    //HERE MAKE API CALL TO UPDATE JOB:
                    const updateData = {
                        jobId: job?._id,
                        job: job,
                        date: selectedDate,
                    }
                    dispatch(updateJob(updateData))

                    // dispatch(getJobListing())

                    // setJobIsUpdated(isJobUpdated)
                    if (jobIsUpdated) {
                        setJobIsError(false)
                        setJobErrorMessage("")
                        onClose();
                    } else {
                        setJobErrorMessage(error)
                        setJobIsError(true)
                        setValidationError(true);
                    }
                    setSearchText("");
                    return [...showSearchedJob];
                }
                // return updatedJobs;
            } else {

                // HERE MAKE API CALL TO ADD NEW JOB:
                if (!(job?.deviceId && job?.partyName && job?.materialName && job?.materialSize && job?.temperature && job?.requiredCoating)) {
                    setJobIsError(true)
                    setJobErrorMessage("Please fill required field.")
                    setValidationError(true);
                    return prevJobs; // Return previous jobs if validation fails
                } else {
                    const formatJobToAdd = {
                        ...job,
                        temperature: Number(job.temperature),
                        coatingMicrons: Number(job?.coatingMicrons),
                        requiredCoating: Number(job?.requiredCoating),
                        weightAfterGI: job?.weightAfterGI ? Number(job?.weightAfterGI) : "",
                        weightBeforeGI: job?.weightBeforeGI ? Number(job?.weightBeforeGI) : "",
                        appliedCoating: isNaN(Number(job?.appliedCoating)) ? '' : (job?.appliedCoating),
                        status: job.status === "Active" ? 1 : job.status === "Hold" ? 0 : 2
                    }
                    const jobToAdd = { ...formatJobToAdd };
                    const jobDataAdd = {
                        ...jobToAdd,
                        companyId: localStorage.getItem("companyId")
                    }
                    dispatch(addUserJob(jobDataAdd));
                    if (jobIsJobAdded) {
                        setJobIsError(false)
                        setJobErrorMessage("")
                        onClose();
                    } else {
                        setJobErrorMessage(error)
                        setJobIsError(true)
                        setValidationError(true);
                    }
                    return [...showSearchedJob];
                }
            }
            
        });
    };




    useEffect(() => {
        setJobIsUpdated(isJobUpdated)
        return () => {
            setJobIsUpdated(false); // Reset or clear state
        };
    }, [isJobUpdated])

    // FOR CLOSING THE DRAWER OF THE UPDATE JOB
    useEffect(() => {
        if (jobIsUpdated) {
            onClose();
            setValidationError(false);
            handleClose()
        }
    }, [jobIsUpdated])


    // FOR CLOSING THE DRAWER OF THE ADD JOB 
    useEffect(() => {
        if (jobIsJobAdded) {
            onClose();
            setValidationError(false);
            handleClose()
        }
    }, [jobIsJobAdded])


    useEffect(() => {
        setJobIsJobAdded(isJobAdded)
    }, [isJobAdded])

    useEffect(() => {
        setJobIsError(isError)
    }, [isError])


    useEffect(() => {
        setJobErrorMessage(error)
    }, [error])





    const handleEditRow = (index: number) => {
        setAddJob(false)
        // SET THE EDIT DATA TO ORIGINAL DATA TYPES:
        const selectedPartyName = partyList.find(party => party.partyName === jobs[index].partyName)
        const selectedDevice = dataDevice.find(device => device.deviceId === jobs[index].deviceId);
        const formatJobToEdit = {
            ...jobs[index],
            partyName: selectedPartyName?._id,
            temperature: Number(jobs[index].temperature),
            deviceId: selectedDevice?._id,
            coatingMicrons: Number(jobs[index].coatingMicrons),
            requiredCoating: Number(jobs[index].requiredCoating),
            weightAfterGI: isNaN(Number(jobs[index].weightAfterGI)) ? '' : (jobs[index].weightAfterGI),
            weightBeforeGI: isNaN(Number(jobs[index].weightBeforeGI)) ? '' : (jobs[index].weightBeforeGI),
            appliedCoating: isNaN(Number(jobs[index].appliedCoating)) ? '' : (jobs[index].appliedCoating),
            status: jobs[index].status === "Active" ? 1 : jobs[index].status === "Hold" ? 0 : 2
        }
        const jobToEdit = { ...formatJobToEdit, index };
        handleButtonClick(<JobComponent onSubmit={handleNewJobSubmit} job={jobToEdit} buttonType='manageJobPage' setButtonType={setButtonType} validationError={validationError} setValidationError={setValidationError} formatDateTime={formatDateTime} />, "editJobTitle");
    };

    // SHOWING THE USER DETAIL SECTION:
    const [showUserList, setShowUserList] = useState(false)
    const isSupervisor = localStorage.getItem('isSupervisor')

    // HIDE SHOW BUTTONS ON CLOSE SIDEBAR
    const handleClose = () => {
        setButtonType("manageJobPage")
        onClose();
    };


    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "Shivam E&F",
        sheet: "Shivam E&F"
    });



    // SEARCH TEXT BOX DATA HERE>>!!!
    const [searchText, setSearchText] = useState("")
    const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setSearchText(value)
        const searchCustomerRes = showSearchedJob.filter((item: any) => {
            const filteredDataOfJob = item.deviceId.toString().toLowerCase().includes(value.toLowerCase()) ||
                item.jobId?.toString().toLowerCase().includes(value.toLowerCase()) ||
                item.partyName?.toString().toLowerCase().includes(value.toLowerCase()) ||
                item.materialName?.toString().toLowerCase().includes(value.toLowerCase()) ||
                item.materialSize?.toString().toLowerCase().includes(value.toLowerCase()) ||
                item.temperature?.toString().toLowerCase().includes(value.toLowerCase()) ||
                item.weightBeforeGI?.toString().toLowerCase().includes(value.toLowerCase()) ||
                item.weightAfterGI?.toString().toLowerCase().includes(value.toLowerCase()) ||
                item.requiredCoating?.toString().toLowerCase().includes(value.toLowerCase()) ||
                item.appliedCoating?.toString().toLowerCase().includes(value.toLowerCase()) ||
                item.coatingMicrons?.toString().toLowerCase().includes(value.toLowerCase()) ||
                item.status?.toString().toLowerCase().includes(value.toLowerCase())
            // item.status?.toString().toLowerCase().includes(value.toLowerCase()) 

            // item.createdAt?.toString().toLowerCase().includes(value.toLowerCase())
            return filteredDataOfJob;
        });

        if (searchCustomerRes.length > 0) {
            setJobs(searchCustomerRes);
        } else {
            setJobs([]);
        }
    }

    return (
        <>
            {jobIsError && jobErrorMessage && <ShowToast message={jobErrorMessage} resStatus="error" setFunction={setJobIsError} />}
            {jobIsJobAdded && <ShowToast message="Job added successfully" resStatus="success" setFunction={setJobIsJobAdded} />}
            {jobIsUpdated && <ShowToast message="Job updated successfully" resStatus="success" setFunction={setJobIsUpdated} />}

            {/* {isLoading ? <Loader /> : ( */}

            <Flex
                mb={8}
                justifyContent="space-between"
                direction={["column", "row"]}
                alignItems="center"
                minWidth='max-content'
                borderBottom={"1px solid #DADADA"} boxShadow={"3px 6px 8px 0px #DADADA"}
                pt={["10px", "20px"]}
                pl={["10px", "20px"]}
                pr={["10px", "20px"]}
            >
                <Flex
                    flexDirection={["column"]}
                    alignItems={"left"}
                >
                    <HStack>
                        <Center width={["30px", "40px"]} height={["30px", "40px"]}>
                            {
                                dataCustomer?.customerLogo ? <Image src={`${logoImage}${dataCustomer?.customerLogo}`} /> : <Image src={logo} />
                            }
                        </Center>

                        <Text fontSize="2xl" fontWeight="bold" >
                            {showUserList ? "Galvanization User" : dataCustomer?.customerName}
                        </Text>
                    </HStack>
                </Flex>
                <Spacer />

                <Flex
                    flexDirection={["column"]}
                    alignItems={"center"}
                >
                    <Flex
                        flexDirection={["row"]}
                        alignItems={"center"}
                    >
                        {/* <Flex
                            flexDirection={["column"]}
                            alignItems={"center"}
                        >
                            <Text fontSize="2xl" fontWeight="bold" >
                                Dashboard
                            </Text>
                        </Flex> */}



                        <Flex
                            flexDirection={["column"]}
                            alignItems={"center"}
                        >
                            <Flex
                                flexDirection={["row"]}
                                mt={["3px", "0px"]}
                            >
                                <Flex
                                    flexDirection={["column"]}
                                    alignItems={"center"}
                                >
                                    <IconButton
                                        ml={"10px"}
                                        variant='outline'
                                        colorScheme="teal"
                                        size={"sm"}

                                        aria-label='last refreshed'
                                        icon={<HiRefresh />}
                                        onClick={() => refreshJob()}
                                    />
                                </Flex>
                                <Flex
                                    flexDirection={["column"]}
                                    alignItems={"center"}
                                >
                                    <Text mt={"3px"} ml={"10px"} color={"teal"}>{refreshTimeStamp}</Text>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                <Spacer />

                <Flex flexDirection={"column"} alignItems={"center"}>
                    <HStack>
                        <Text m={["0px", "0.9em"]} fontSize="lr" fontWeight="bold">
                            Welcome {localStorage.getItem('userName')} !
                        </Text>
                        <Box mr={["10px", "0px"]}>
                            <Logout />
                        </Box>
                    </HStack>
                </Flex>
            </Flex>

            <Container maxW='container.2xl' h="100vh">
                <Box bgColor={"#fff"} h="100%">

                    <Flex
                        flexDirection={["column"]}
                        bgColor={"#fff"}
                    >
                        <Flex
                            flexDirection={["column", "row"]}
                            alignItems={"left"}
                            bgColor={"#fff"}
                            mt={["-15px", "-12px"]}
                        >

                            <Flex
                                flexDirection={["column"]}
                                alignItems={"center"}
                            >

                                <Flex
                                    flexDirection={["row"]}
                                    alignItems={"center"}
                                >
                                    {
                                        isSupervisor === "1" &&
                                        <Flex
                                            flexDirection={["column"]}
                                            alignItems={"center"}
                                            ml={"5px"}
                                        >
                                            <Button className={styles.tableBtn} colorScheme={buttonType === 'manageUserPage' ? 'teal' : 'gray'} size="md"
                                                onClick={() => handleButtonClick(<User buttonType="manageUserPage" setButtonType={setButtonType} handleClose={handleClose} />, "UserDetailTitle")}>
                                                Manage Users
                                            </Button>
                                        </Flex>
                                    }

                                    <Flex
                                        flexDirection={["column"]}
                                        alignItems={"center"}
                                        ml={"5px"}
                                    >
                                        <Button className={styles.tableBtn} colorScheme={buttonType === 'manageJobPage' ? 'teal' : 'gray'} size="md" onClick={() => {
                                            setShowUserList(false);
                                            handleButtonClick(<JobComponent onSubmit={handleNewJobSubmit} newJob={true} buttonType="manageJobPage" setButtonType={setButtonType} validationError={validationError} setValidationError={setValidationError} formatDateTime={formatDateTime} />, "newJobTitle");
                                        }}>
                                            New Job
                                        </Button>
                                    </Flex>


                                    <Flex
                                        flexDirection={["column"]}
                                        alignItems={"center"}
                                        ml={"5px"}
                                    >
                                        <Button
                                            // isLoading={isLoading}
                                            // loadingText='login'
                                            className={styles.tableBtn}
                                            colorScheme={buttonType === 'managePartiesPage' ? 'teal' : 'gray'}
                                            size="md"
                                            onClick={() => handleButtonClick(<ManagePartiesComponent buttonType="managePartiesPage" setButtonType={setButtonType} />, "managePartyTitle")}
                                        >
                                            Manage Parties
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Flex>

                            <Spacer />
                            <Flex
                                flexDirection={["row"]}
                                alignItems={"center"}
                                mr={"5px"}
                                mb={["0px", "10px"]}
                                mt={["-6px", "0px"]}
                            >
                                <Flex
                                    flexDirection={["column"]}
                                    alignItems={"center"}
                                    // mr={"5px"}
                                    width={"100%"}
                                >
                                    <Input
                                        size={"md"}
                                        placeholder='Search'
                                        color={"gray"}
                                        borderRadius={"6px"}
                                        border={"1px solid teal"}
                                        name="searchText"
                                        value={searchText}
                                        onChange={(e) => handleSearchText(e)}
                                    />
                                </Flex>
                            </Flex>

                            <Flex
                                flexDirection={["column"]}
                                alignItems={"center"}
                            >

                                <Flex
                                    flexDirection={["row"]}
                                    alignItems={"center"}
                                    mr={"5px"}
                                >

                                    <Flex
                                        flexDirection={["column"]}
                                        alignItems={"center"}
                                        mr={"5px"}
                                    >
                                        <Tooltip fontWeight={"bold"} label="Export Excel" hasArrow aria-label='A tooltip' bg='teal.500' color='white'>
                                            <Box cursor={"pointer"}>
                                                <SiMicrosoftexcel size={"30px"} onClick={onDownload} />
                                            </Box>
                                        </Tooltip>
                                    </Flex>

                                    <Flex
                                        flexDirection={["column"]}
                                        alignItems={"center"}
                                        m={["5px", "0px"]}
                                    >
                                        <Flex
                                            flexDirection={["row"]}
                                            alignItems={"center"}
                                            width={"100%"}
                                        >
                                            <Input
                                                size={"md"}
                                                color={"gray"}
                                                borderRadius={"none"}
                                                borderLeftRadius={"6px"}
                                                type="date"
                                                name="datetime-local"
                                                border={"1px solid teal"}
                                                borderRight={"none"}
                                                value={selectedDate}
                                                onChange={(e) => handleDateChange(e)}
                                            />
                                            <Button
                                                size={["md"]}
                                                colorScheme="teal"
                                                borderRadius={"none"}
                                                borderRightRadius={"6px"}
                                                borderLeft={"none"}
                                                fontSize={"sm"}
                                                onClick={() => handleDateSearch()}
                                            >Show</Button>
                                        </Flex>
                                    </Flex>


                                </Flex>


                            </Flex>
                        </Flex>




                        <Flex
                            direction={"row"}
                            alignItems={"center"}
                        >
                            {/* <Box overflowX="auto"> */}
                            {/* <TableContainer width={"100%"} overflowX="auto"> */}
                            <TableContainer width={"100%"} ref={tableRef}>
                                {/* <Table className={styles.maintable} ref={tableRef}> */}
                                <Table colorScheme='teal' fontSize={"13px"} width={"100%"}>

                                    <Thead>
                                        {showUserList ? <User buttonType="manageUserPage" setButtonType={setButtonType} handleClose={handleClose} /> :
                                            <Tr height={"60px"} bgColor='teal' opacity={"0.8"} color={"#fff"} >
                                                <Th padding={"1px"}
                                                    color={"white"}
                                                    fontSize={["13px", "sm"]}
                                                    sx={{
                                                        textTransform: 'none',
                                                        textAlign: 'center',
                                                        maxWidth: '120px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap'
                                                    }} >
                                                    Device Id
                                                </Th>
                                                <Th
                                                    padding={"1px"}
                                                    color={"white"}
                                                    fontSize={["13px", "sm"]}
                                                    sx={{
                                                        textTransform: 'none',
                                                        textAlign: 'center',
                                                        maxWidth: '120px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap'
                                                    }} >
                                                    Job Id
                                                </Th>
                                                <Th
                                                    padding={"1px"}
                                                    color={"white"}
                                                    fontSize={["13px", "sm"]}
                                                    sx={{
                                                        textTransform: 'none',
                                                        textAlign: 'center',
                                                        maxWidth: '250px',
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'normal'
                                                    }} >
                                                    Party
                                                </Th>

                                                <Th
                                                    padding={"1px"}
                                                    color={"white"}
                                                    fontSize={["13px", "sm"]}
                                                    sx={{
                                                        textTransform: 'none',
                                                        textAlign: 'center',
                                                        maxWidth: '200px',
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'normal',
                                                    }} >
                                                    Material
                                                </Th>
                                                <Th
                                                    padding={"1px"}
                                                    color={"white"}
                                                    fontSize={["13px", "sm"]}
                                                    sx={{
                                                        textTransform: 'none',
                                                        textAlign: 'center',
                                                        maxWidth: '60px',
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'normal'
                                                    }} >
                                                    Material SZ/THK
                                                </Th>
                                                <Th
                                                    padding={"1px"}
                                                    color={"white"}
                                                    fontSize={["13px", "sm"]}
                                                    sx={{
                                                        textTransform: 'none',
                                                        textAlign: 'center',
                                                        maxWidth: '120px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap'
                                                    }} >
                                                    Tmp
                                                </Th>

                                                <Th
                                                    padding={"1px"}
                                                    color={"white"}
                                                    fontSize={["13px", "sm"]}
                                                    sx={{
                                                        textTransform: 'none',
                                                        textAlign: 'center',
                                                        maxWidth: '40px',
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'normal'
                                                    }} >
                                                    Wt before G.I.
                                                </Th>
                                                <Th
                                                    padding={"1px"}
                                                    color={"white"}
                                                    fontSize={["13px", "sm"]}
                                                    sx={{
                                                        textTransform: 'none',
                                                        textAlign: 'center',
                                                        maxWidth: '40px',
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'normal'
                                                    }} >
                                                    Wt after G.I.
                                                </Th>
                                                <Th
                                                    padding={"1px"}
                                                    color={"white"}
                                                    fontSize={["13px", "sm"]}
                                                    sx={{
                                                        textTransform: 'none',
                                                        textAlign: 'center',
                                                        maxWidth: '60px',
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'normal'
                                                    }} >
                                                    Coating req (%)
                                                </Th>

                                                <Th
                                                    padding={"1px"}
                                                    color={"white"}
                                                    fontSize={["13px", "sm"]}
                                                    sx={{
                                                        textTransform: 'none',
                                                        textAlign: 'center',
                                                        maxWidth: '60px',
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'normal'
                                                    }} >
                                                    Coating appl (%)
                                                </Th>
                                                <Th
                                                    padding={"1px"}
                                                    color={"white"}
                                                    fontSize={["13px", "sm"]}
                                                    sx={{
                                                        textTransform: 'none',
                                                        textAlign: 'center',
                                                        maxWidth: '60px',
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'normal'
                                                    }} >
                                                    Coating in microns
                                                </Th>
                                                <Th
                                                    padding={"1px"}
                                                    color={"white"}
                                                    fontSize={["13px", "sm"]}
                                                    sx={{
                                                        textTransform: 'none',
                                                        textAlign: 'center',
                                                        maxWidth: '60px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap'
                                                    }} >
                                                    {/* <Tooltip label="Type" hasArrow aria-label='A tooltip' bg='teal.500' color='white'> */}
                                                    Status
                                                    {/* </Tooltip> */}
                                                </Th>
                                                <Th
                                                    padding={"1px"}
                                                    color={"white"}
                                                    fontSize={["13px", "sm"]}
                                                    sx={{
                                                        textTransform: 'none',
                                                        textAlign: 'center',
                                                        maxWidth: '60px',
                                                        // overflow: 'hidden',
                                                        // textOverflow: 'ellipsis',
                                                        // whiteSpace: 'nowrap'
                                                        whiteSpace: 'normal',
                                                        wordWrap: 'break-word',
                                                    }} >
                                                    {/* <Tooltip label="Time" hasArrow aria-label='A tooltip' bg='teal.500' color='white'> */}
                                                    Created at
                                                    {/* </Tooltip> */}
                                                </Th>
                                                <Th
                                                    padding={"1px"}
                                                    color={"white"}
                                                    fontSize={["13px", "sm"]}
                                                    sx={{
                                                        textTransform: 'none',
                                                        textAlign: 'center',
                                                        maxWidth: '40px',
                                                        // overflow: 'hidden',
                                                        // textOverflow: 'ellipsis',
                                                        // whiteSpace: 'nowrap',
                                                        // whiteSpace: 'normal',
                                                        wordWrap: 'break-word',
                                                    }} >
                                                    {/* <Tooltip label="Type" hasArrow aria-label='A tooltip' bg='teal.500' color='white'> */}
                                                    Action
                                                    {/* </Tooltip> */}
                                                </Th>
                                            </Tr>
                                        }
                                    </Thead>

                                    <Tbody>
                                        {isLoading ? (
                                            <Tr>
                                                <Td colSpan={14} style={{ textAlign: 'center', borderColor: 'white' }}>
                                                    <Loader />
                                                </Td>
                                            </Tr>
                                        ) : jobs && jobs.length > 0 ? (
                                            jobs && jobs.map((dataForSearch: any, index: number) => {
                                                const isCoatingTrue = (Number(dataForSearch?.appliedCoating)) > Number(dataForSearch?.requiredCoating);
                                                // const dataDeviceId = dataDevice.find(data => data._id === job.deviceId);
                                                // const matchingParty = dataParty.find(data => data._id === job.partyName);
                                                const date = new Date(dataForSearch.createdAt);
                                                const dateCreatedAt = formatDateTime(date);

                                                return (
                                                    // <Tr key={index} color={GIError ? "" : "red"}>
                                                    <Tr key={index} bgColor={isCoatingTrue ? "#ffebe6" : ""} height={"50px"}>
                                                        <Td padding={"1px"} textAlign={"center"}>{dataForSearch?.deviceId}</Td>
                                                        <Td padding={"1px"} textAlign={"center"}>{dataForSearch.jobId}</Td>
                                                        <Td padding={"1px"} textAlign={"center"}>{dataForSearch?.partyName}</Td>
                                                        <Td padding={"1px"} textAlign={"center"}>{dataForSearch.materialName}</Td>
                                                        <Td padding={"1px"} textAlign={"center"}>{dataForSearch.materialSize}</Td>
                                                        <Td padding={"1px"} textAlign={"center"}>{dataForSearch.temperature}</Td>
                                                        <Td padding={"1px"} textAlign={"center"}
                                                            sx={{
                                                                textTransform: 'none',
                                                                textAlign: 'center',
                                                                maxWidth: '40px',
                                                                wordWrap: 'break-word',
                                                                whiteSpace: 'normal'
                                                            }}
                                                        >
                                                            {dataForSearch?.weightBeforeGI && Number(dataForSearch?.weightBeforeGI).toFixed(2)}
                                                        </Td>
                                                        <Td padding={"1px"} textAlign={"center"}
                                                            sx={{
                                                                textTransform: 'none',
                                                                textAlign: 'center',
                                                                maxWidth: '40px',
                                                                wordWrap: 'break-word',
                                                                whiteSpace: 'normal'
                                                            }}
                                                        >
                                                            {dataForSearch?.weightAfterGI && Number(dataForSearch?.weightAfterGI).toFixed(2)}
                                                        </Td>
                                                        <Td padding={"1px"} textAlign={"center"}
                                                            sx={{
                                                                textTransform: 'none',
                                                                textAlign: 'center',
                                                                maxWidth: '40px',
                                                                wordWrap: 'break-word',
                                                                whiteSpace: 'normal'
                                                            }}
                                                        >
                                                            {dataForSearch?.requiredCoating && Number(dataForSearch?.requiredCoating).toFixed(2)}
                                                        </Td>
                                                        <Td padding={"1px"} textAlign={"center"}
                                                            sx={{
                                                                textTransform: 'none',
                                                                textAlign: 'center',
                                                                maxWidth: '40px',
                                                                wordWrap: 'break-word',
                                                                whiteSpace: 'normal'
                                                            }}
                                                        >
                                                            {dataForSearch?.appliedCoating && Number(dataForSearch?.appliedCoating).toFixed(2)}
                                                        </Td>
                                                        <Td padding={"1px"} textAlign={"center"}
                                                            sx={{
                                                                textTransform: 'none',
                                                                textAlign: 'center',
                                                                maxWidth: '60px',
                                                                wordWrap: 'break-word',
                                                                whiteSpace: 'normal',
                                                            }}
                                                        >
                                                            {dataForSearch?.coatingMicrons && Number(dataForSearch?.coatingMicrons).toFixed(2)}
                                                        </Td>
                                                        <Td padding={"1px"} textAlign={"center"}
                                                            sx={{
                                                                textTransform: 'none',
                                                                textAlign: 'center',
                                                                maxWidth: '60px',
                                                                wordWrap: 'break-word',
                                                                whiteSpace: 'normal',
                                                            }}
                                                        >{dataForSearch.status}</Td>
                                                        <Td padding={"1px"} textAlign={"center"}
                                                            sx={{
                                                                textTransform: 'none',
                                                                textAlign: 'center',
                                                                maxWidth: '60px',
                                                                wordWrap: 'break-word',
                                                                whiteSpace: 'normal',
                                                            }}
                                                        >{dateCreatedAt}</Td>
                                                        <Td padding={"1px"}
                                                            sx={{
                                                                textTransform: 'none',
                                                                textAlign: 'center',
                                                                maxWidth: '40px',
                                                                wordWrap: 'break-word',
                                                                whiteSpace: 'normal',
                                                            }}
                                                        >
                                                            <Tooltip label="Update Job" hasArrow aria-label='A tooltip' color='white' bg='teal.500'>
                                                                <EditIcon mx={2} _hover={{ cursor: "pointer" }} boxSize={4} onClick={() => handleEditRow(index)} />
                                                            </Tooltip>
                                                        </Td>
                                                    </Tr>
                                                );
                                            })
                                        ) : (
                                            <Tr>
                                                <Td colSpan={14} style={{ textAlign: 'center' }}>
                                                    <Text color={"teal"} fontSize={"18px"} fontWeight={"bold"}>No record found for current date</Text>
                                                </Td>
                                            </Tr>
                                        )}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                            {/* </Box> */}
                        </Flex>
                    </Flex>

                    {/* <Drawer isOpen={isOpen} placement="right" onClose={onClose}> */}
                    <Drawer isOpen={isOpen} placement="right" onClose={handleClose} size="md">
                        <DrawerOverlay />
                        <DrawerContent >
                            <DrawerCloseButton />
                            {
                                (pageType === "UserDetailTitle") ? <DrawerHeader borderBottom={"1px solid #DADADA"} boxShadow={"3px 6px 8px 0px #DADADA"}>{sidebarContent ? 'User Listing' : ''}</DrawerHeader> :
                                    (pageType === "newJobTitle") ? <DrawerHeader borderBottom={"1px solid #DADADA"} boxShadow={"3px 6px 8px 0px #DADADA"}>{sidebarContent ? 'Details' : ''}</DrawerHeader> :
                                        (pageType === "editJobTitle") ? <DrawerHeader borderBottom={"1px solid #DADADA"} boxShadow={"3px 8px 4px 0px #DADADA"}>{sidebarContent ? 'Edit Job' : ''}</DrawerHeader> :
                                            (pageType === "managePartyTitle") ? <DrawerHeader borderBottom={"1px solid #DADADA"} boxShadow={"3px 6px 8px 0px #DADADA"}>{sidebarContent ? 'Manage Parties' : ''}</DrawerHeader> : ""
                            }
                            <DrawerBody>
                                {sidebarContent}
                            </DrawerBody>
                        </DrawerContent>
                    </Drawer>
                </Box>
            </Container>
        </>

    );
};

export default Galvanization;
