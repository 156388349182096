import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as deviceServices from '../services/deviceServices';
import apiInstance from "../config/api/axios";
export type IDRootState = {
    device: DeviceState;
}

type DeviceState = {
    dataDevice: any[];
    isLoading: boolean;
    error: null | string;
    invalidDeviceError: null | string;
}

const initialState: DeviceState = {
    dataDevice: [],
    isLoading: true,
    error: null,
    invalidDeviceError: null
}





const deviceSlice = createSlice({
    name: 'device',
    initialState,
    reducers: {
        getAllDevice(state, action) {
            state.dataDevice = action.payload;
        },
        onChangeDevice(state, action) {
            const { index, fieldName, value } = action.payload;
            state.dataDevice = state.dataDevice.map((data, i) => {
                if (index === i) {
                    return {
                        ...data,
                        [fieldName]: value,
                    };
                }
                return data;
            });
        },
        statusLoading(state, action) {
            state.isLoading = action.payload;
        },
        statusError(state, action) {
            state.error = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllDevices.pending, (state) => {
            state.isLoading = true
        })
            .addCase(getAllDevices.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.dataDevice = action.payload;
            })
            .addCase(getAllDevices.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = action.payload
            })
            .addCase(getDevicesById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getDevicesById.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.dataDevice = action.payload
            })
            .addCase(getDevicesById.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = action.payload
            })
            .addCase(getUserCompanyDevices.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getUserCompanyDevices.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.dataDevice = action.payload
            })
            .addCase(getUserCompanyDevices.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = action.payload
            })
            .addCase(getUserDeviceDataByDeviceId.pending, (state) => {
                state.isLoading = false;
            })
            .addCase(getUserDeviceDataByDeviceId.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.invalidDeviceError = null;
                state.dataDevice = action.payload
            })
            .addCase(getUserDeviceDataByDeviceId.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.invalidDeviceError = action.payload
                // state.error = action.payload
            })
            .addCase(getUserDeviceDataByDeviceIdAndDate.pending, (state) => {
                state.isLoading = false;
            })
            .addCase(getUserDeviceDataByDeviceIdAndDate.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.dataDevice =  action.payload;
                state.invalidDeviceError = null;
            })
            .addCase(getUserDeviceDataByDeviceIdAndDate.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.invalidDeviceError = action.payload
            })
    }
})

export default deviceSlice.reducer;
export const { getAllDevice, onChangeDevice, statusLoading, statusError } = deviceSlice.actions;


// API FOR GET ALL DEVICES
export const getAllDevices = createAsyncThunk("getAllDevices", async (_, thunkAPI): Promise<any> => {
    try {
        const response = await apiInstance.get(`/api/admin/get-all-devices`)
        return response?.data?.payload
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
})

// API FOR GET DEVICE BY ID:
export const getDevicesById = createAsyncThunk("getDevicesById", async (comapanyId: string | undefined, thunkAPI): Promise<any> => {
    try {
        const response = await apiInstance.get(`/api/admin/get-devices/${comapanyId}`);
        return response?.data?.payload
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
})

// API FOR GET USER COMPANY DEVICES:
export const getUserCompanyDevices = createAsyncThunk("getUserCompanyDevices", async (_, thunkAPI): Promise<any> => {
    try {
        const response = await apiInstance.get(`/api/user/get-user-company-devices`);
        return response?.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
})

// API FOR GETTING USER DEVICEDATA BY SELECTING DEVICE ID
export const getUserDeviceDataByDeviceId = createAsyncThunk("getUserDeviceDataByDeviceId", async (deviceId: any, thunkAPI): Promise<any> => {
    try {
        const response = await apiInstance.post(`/api/user/forging-stats`, deviceId);
        return response?.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
})


// API FOR GETTING USER DEVICE DATA BY SELECTING DEVICE ID AND DATE FROM DATE PICKER
export const getUserDeviceDataByDeviceIdAndDate = createAsyncThunk("getUserDeviceDataByDeviceIdAndDate", async (deviceIdDate: any, thunkAPI): Promise<any> => {
    try {
        const response = await apiInstance.post(`/api/user/forging-stats`, deviceIdDate);
        return response?.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
})