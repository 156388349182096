import { IAdminLogin } from "../constants/loginTypes";
import { IUserLogin } from "../constants/loginTypes";
import apiInstance from "../config/api/axios"

// LOGIN API
export const adminLogIn = async (data: IAdminLogin) => {
    const response = await apiInstance.post(`/api/admin/login`, data);
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('isSuperAdmin', "1");
    localStorage.setItem('adminUserName', data.email)
    return response
}


// USER LOGIN
export const userLogIn = async (data: IUserLogin) => {
    const response = await apiInstance.post(`/api/user/userlogin`, data);
    const isSupervisor = response?.data?.userData?.isSupervisor;
    localStorage.setItem('token', response?.data?.userData?.token);
    localStorage.setItem('isSuperAdmin', '0')
    localStorage.setItem('isSupervisor', isSupervisor ? '1': '0')
    localStorage.setItem('customerType',response?.data?.userData?.company?.customerType)
    localStorage.setItem('notification',response?.data?.userData?.company?.notification)
    localStorage.setItem('companyId', response?.data?.userData?.company?._id)
    localStorage.setItem('userName', response?.data?.userData?.username)
    return response
}



