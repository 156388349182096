import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Select,
    Radio,
    RadioGroup,
    Stack,
    VStack,
} from '@chakra-ui/react';

import { useAppSelector, useAppDispatch } from '../Redux/hooks';
import { getUserCompanyDevices } from '../Redux/deviceSlice';
import { getPartyListing } from '../Redux/partySlice';
import { IJobData } from '../constants/jobTypes'

const JobComponent: React.FC<{ onSubmit: (job: any) => void; job?: any; newJob?: any; buttonType: string; validationError?: boolean; setValidationError?: any; setButtonType: React.Dispatch<React.SetStateAction<string>>; formatDateTime: any }> = ({ onSubmit, job, newJob, buttonType, validationError, setValidationError, setButtonType, formatDateTime }) => {

    const { dataParty, isLoading } = useAppSelector((state) => state.party)
    const { dataDevice } = useAppSelector((state) => state.device)
    const { dataJob, error, isError } = useAppSelector((state) => state.job)
    const [deviceList, setDeviceList] = useState<any[]>([])
    const [partyList, setPartyList] = useState<any[]>([])
    const dispatch = useAppDispatch();
    const isSupervisor = localStorage.getItem('isSupervisor')

    //API FOR GETTING USER COMPANY DEVICES:
    useEffect(() => {
        // isSupervisor === "1" ? dispatch(getAllDevices()) : dispatch(getUserCompanyDevices())
        dispatch(getUserCompanyDevices())
        dispatch(getPartyListing(localStorage.getItem("companyId")))
    }, [dispatch])

    //SETTING ALL PARTY LIST:
    useEffect(() => {
        setButtonType(buttonType)
        setPartyList(dataParty)
    }, [dataParty])


    // SETTING ALL DEVICES:
    useEffect(() => {
        setDeviceList(dataDevice)
    }, [dataDevice])

    const initialJobDetails: IJobData = job || {
        deviceId: '',
        partyName: '',
        materialName: '',
        materialSize: '',
        temperature: '',
        time: '',
        weightBeforeGI: '',
        weightAfterGI: '',
        zincCoating: '',
        coatingMicrons: '',
        accuracyRequired: '',
    };

    const [jobDetails, setJobDetails] = useState(initialJobDetails);
    const [showCoating, setShowCoating] = useState(true)

    const handleSubmit = () => {
        onSubmit(jobDetails);
        if (validationError) {
            setValidationError(false);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setJobDetails({
            ...jobDetails,
            [e.target.name]: e.target.value
        })
    }

    const handleAccuracyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setJobDetails({
            ...jobDetails,
            [e.target.name]: e.target.value
        })
    }

    // FOR DISPLAY COATING IN MICRONS FIELD
    useEffect(() => {
        if (jobDetails.weightAfterGI && jobDetails.weightBeforeGI) {
            setShowCoating(false)
        } else {
            setShowCoating(true)
        }
    }, [jobDetails.weightAfterGI, jobDetails.weightBeforeGI])


    const handlePartyNameChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setJobDetails({
            ...jobDetails,
            [e.target.name]: e.target.value
        });
    }

    const handleDeviceNameChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setJobDetails({
            ...jobDetails,
            [e.target.name]: e.target.value
        });
    }

    const handleTypeChange = (value: string) => {
        const stringValue = value.toString();
        setJobDetails({ ...jobDetails, status: stringValue });
    };

    // FOR TIME FORMAT IN DRAWER
    const date = new Date(jobDetails?.createdAt);
    const headerTimejobEdit = formatDateTime(date);

    return (
        <Box p={4}>
            <Box as="h2" mb={4}>
                {job ? `${jobDetails.jobId} / ${headerTimejobEdit}` : 'New Job'}
            </Box>
            <VStack spacing={4} align="stretch">

                <FormControl mt={"2px"} isRequired >
                    <FormLabel fontSize={"sm"}>Devices</FormLabel>
                    <Select
                        disabled={!newJob}
                        fontSize={"sm"}
                        color={"gray.500"}
                        placeholder="Select device"
                        value={jobDetails.deviceId}
                        name='deviceId'
                        onChange={(e) => handleDeviceNameChange(e)}
                    >
                        {dataDevice.map((device, index) => (
                            <option key={index} value={device?._id}>
                                {`${device?.deviceName} (${device.deviceId})`}
                            </option>
                        ))}
                    </Select>
                </FormControl>

                <FormControl mt={"2px"} isRequired >
                    <FormLabel fontSize={"sm"}>Party Name</FormLabel>
                    <Select
                        fontSize={"sm"}
                        color={"gray.500"}
                        placeholder="Select party name"
                        value={jobDetails.partyName}
                        name='partyName'
                        onChange={(e) => handlePartyNameChange(e)}
                    >
                        {partyList.map((party, index) => (
                            <option
                                key={index}
                                value={party?._id}
                            >
                                {party?.partyName}
                            </option>
                        ))}
                    </Select>
                </FormControl>

                <FormControl mt={"2px"} isRequired >
                    <FormLabel fontSize={"sm"} >Material Name</FormLabel>
                    <Input
                        fontSize={"sm"}
                        placeholder="Enter material name"
                        value={jobDetails.materialName}
                        name='materialName'
                        onChange={(e) => handleChange(e)}
                    />
                </FormControl>

                <FormControl mt={"2px"} isRequired >
                    <FormLabel fontSize={"sm"}>Material Size/Thickness</FormLabel>
                    <Input
                        fontSize={"sm"}
                        placeholder="Enter material size/thickness"
                        value={jobDetails.materialSize}
                        name='materialSize'
                        onChange={(e) => handleChange(e)}
                    />
                </FormControl>

                <FormControl mt={"2px"} isRequired>
                    <FormLabel fontSize={"sm"}>Temperature</FormLabel>
                    <Input
                        fontSize={"sm"}
                        placeholder="Enter temperature"
                        value={jobDetails.temperature}
                        name='temperature'
                        onChange={(e) => handleChange(e)}
                    />
                </FormControl>

                <FormControl mt={"2px"}>
                    <FormLabel fontSize={"sm"}>Weight Before G.I.</FormLabel>
                    <Input
                        disabled={isSupervisor !== '1' ? true : false}
                        fontSize={"sm"}
                        placeholder="Enter weight before G.I."
                        value={jobDetails?.weightBeforeGI}
                        name='weightBeforeGI'
                        onChange={(e) => handleChange(e)}
                    />
                </FormControl>

                <FormControl mt={"2px"}>
                    <FormLabel fontSize={"sm"}>Weight After G.I.</FormLabel>
                    <Input
                        disabled={isSupervisor !== '1' ? true : false}
                        fontSize={"sm"}
                        placeholder="Enter weight after G.I."
                        value={jobDetails?.weightAfterGI}
                        name='weightAfterGI'
                        onChange={(e) => handleChange(e)}
                    />
                </FormControl>


                <FormControl mt={"2px"} isRequired>
                    <FormLabel fontSize={"sm"}>Coating required (%)</FormLabel>
                    <Input
                        fontSize={"sm"}
                        placeholder="Enter coating required"
                        value={jobDetails?.requiredCoating || ""}
                        name='requiredCoating'
                        onChange={(e) => handleChange(e)}
                    />
                </FormControl>

                <FormControl mt={"2px"}>
                    <FormLabel fontSize={"sm"}>Coating applied (%)</FormLabel>
                    <Input
                        disabled
                        // disabled={showCoating}
                        fontSize={"sm"}
                        placeholder="Enter coating applied"
                        value={isNaN(Number(jobDetails.appliedCoating))
                            ? ''
                            : Number(jobDetails.appliedCoating).toFixed(2)}
                        name='appliedCoating'
                        onChange={(e) => handleChange(e)}
                    />
                </FormControl>

                <FormControl mt={"2px"}>
                    <FormLabel fontSize={"sm"}>Coating in Microns</FormLabel>
                    <Input
                        fontSize={"sm"}
                        placeholder="Enter coating in microns"
                        value={jobDetails?.coatingMicrons}
                        name="coatingMicrons"
                        onChange={(e) => handleChange(e)}
                    />

                </FormControl>
                {
                    !newJob &&
                    <FormControl mt={"2px"}>
                        <FormLabel fontSize={"sm"}>Status</FormLabel>
                        <RadioGroup defaultValue={jobDetails?.status.toString()} onChange={(e) => handleTypeChange(e)}>
                            <Stack direction='row' >
                                <Radio colorScheme="teal" value="0" ><Box as="span" fontSize={"sm"}>Hold</Box></Radio>
                                <Radio colorScheme="teal" value="1" ><Box as="span" fontSize={"sm"}>Active</Box></Radio>
                                <Radio colorScheme="teal" value="2"><Box as="span" fontSize={"sm"}>Completed</Box></Radio>
                            </Stack>
                        </RadioGroup>
                    </FormControl>
                }

                <Button
                    colorScheme="teal"
                    mt={4}
                    onClick={handleSubmit}
                    size={"sm"}
                >
                    {job ? "update" : "Add"}
                </Button>
            </VStack>
        </Box >
    );
};


export default JobComponent