import React, { useState } from 'react';
import {
  Container,
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  Center,
  Text,
} from '@chakra-ui/react';

const SignUp: React.FC = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    console.log('Username:', username);
    console.log('Email:', email);
    console.log('Password:', password);
  };

  return (
      <Container maxW="md">
        <Center h="100vh">
          <Box
            p={8}
            maxW="md"
            borderWidth={1}
            borderRadius={8}
            boxShadow="lg"
            borderColor="gray.200"
          >
            <Text fontSize="2xl" fontWeight="bold" mb={4}>IOT Dashboard</Text>
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <FormControl id="username" isRequired>
                <FormLabel>Username</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter your username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </FormControl>

              <FormControl id="email" isRequired mt={4}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>

              <FormControl id="password" isRequired mt={4}>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>

              <FormControl id="password" isRequired mt={4}>
                <FormLabel>Repeat Password</FormLabel>
                <Input
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>

              <Button backgroundColor="black" color="white" type="submit" mt={4} w="100%">
                Sign Up
              </Button>
            </form>
          </Box>
        </Center>
      </Container>
  );
};

export default SignUp;
