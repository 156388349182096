import React from 'react';
import { Outlet, Navigate } from "react-router-dom";


  
const AdminPrivateRoutes = (props:any) => {
    let auth = localStorage?.getItem('token') ?  {'isLoggedIn': true} : {'isLoggedIn': false}
    let isSuperAdmin = localStorage?.getItem('isSuperAdmin');
    return(
        (auth.isLoggedIn && isSuperAdmin === '1')? <Outlet/> : <Navigate to="/admin/login" />
    )
}

export default AdminPrivateRoutes;